import React from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import MasCeleste from '../img/mas-celeste.png'


export default function OtrosConsejos(props) {
    console.log(props)
    return (
        <div className="otros-consejos container">
            <h3>OTROS CONSEJOS</h3>
            <div className="consejos">
                {props.consejos.map(consejo => (
                    <Link class={"col-6"} to={`/consejos/${consejo['slug']}`} >
                        <div className="consejo">
                            <div className="image">
                                <img src={`${props.api}/diets/media/${consejo['file']}`} />

                            </div>
                            <div className="content">
                                <h4 id="oc-title" className="font-weight-bold" >{consejo['title']}</h4>
                                {ReactHtmlParser(consejo['description2'])}
                                {/* <img src={MasCeleste} className="mas" alt="Leer Mas" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g transform="translate(-1096.5 -1336.5)">
                                        <g transform="translate(1093.5 1333.5)">
                                            <path d="M7.5,4.5h21a3,3,0,0,1,3,3v21a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path d="M18,12V24" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path d="M12,18H24" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}