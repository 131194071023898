import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../css/swiper.min.css';
import SideNav from '../components/SideNav'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'
import NotificacionLegal from "../components/NotificacionLegal";

export default function NotificacionLegalContainer() {

    const bodyClass = useSelector((state) => state.menu.bodyClass);
    console.log(bodyClass);

    return (
        <div id="notificacion-legal">
            <Helmet>
                <title>Más Que Glucosa | Notificación Legal y Términos de uso</title>
                <meta name="description" content="Notificación Legal y Términos de uso"/>
                <SEO />
            </Helmet>
            <div className={bodyClass}>
                <Navbar />
                <SideNav />
                <main className={"container-fluid"}>
                    <div className={"bg-blue py-5"}>
                        <div className={"container"}>
                            <h1 className={"text-white"}>Aviso Legal y Términos de uso</h1>
                        </div>
                    </div>
                    <NotificacionLegal/>
                </main>
                <Footer />
            </div>
        </div>
    )
}