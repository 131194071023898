import React from 'react'
import logoAz from '../img/logo-az.png'
import logoFundacion from '../img/logo_fundacion_pro_salud.png'
import logoAsodi from '../img/asodi.png'
import logoAdich from '../img/adich.png'


export default function Sociedades() {




    const settings = {

        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",

        arrows: false,
        slidesToShow: 3,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    };
    return (
        <div>
            <section id="sociedades">
                <div className="container">

                    <h5 className="text-center mb-5">CAMPAÑA DESARROLLADA EN CONJUNTO POR</h5>

                    <div className="logos logos-desktop">
                        
                        <div className="logo d-flex justify-content-center">
                            <a href="https://www.astrazeneca.com/country-sites/argentina.html" target="_blank" className="sociedades-img"><img src={logoAz}></img></a>
                            <p className="nombre">AstraZeneca</p>
                            <p className="direccion">Isidora Goyenechea 3477, Santiago de Chile
                            <br /> +56 227980800</p>
                            <p className="direccion mt-2"> Para reportar algún efecto adverso <a href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/cl/es/amp-form.html?counrty=cl&lang=es" target="_blank" className="text-celeste">hacer click aqui</a> o llamar al 0800 333 1247. </p>
                            <div className="links">
                                <a href="" className="red-social" target="_blank"></a>
                                <a href="" className="sitio-web" target="_blank"></a>
                            </div>
                        </div>
                        <div className="logo d-flex justify-content-center">
                            <a href="#" className="sociedades-img" style={{marginBottom:'7rem'}}><img src={logoFundacion} style={{maxHeight:'80px'}}/></a>
                        </div>
                        <div className="logo d-flex justify-content-center">
                            <a href="#" className="sociedades-img" style={{marginBottom:'7rem'}}><img src={logoAdich}/></a>
                        </div>
                        <div className="logo d-flex justify-content-center">
                            <a href="#" className="sociedades-img" style={{marginBottom:'7rem'}}><img src={logoAsodi}/></a>
                        </div>
                        
                    </div>
                    {/* <div class="apoyo row">
                        <div class="col-12">
                            <h5 class="text-center mb-5">CON EL APOYO DE</h5>
                        </div>
                        <div class="col-12 logos">
                            <a href="https://www.teamd.com.ar/" target="_blank">
                                <img src={logo1} /></a>
                            <a href="https://www.facebook.com/pages/category/Non-Governmental-Organization--NGO-/Lapdi-Diabetes-1078198825560061/" target="_blank">
                                <img src={logo2} /></a>
                            <a href="https://www.fad.org.ar/" target="_blank">
                                <img src={logo3} /></a>
                        </div>
                    </div> */}
                </div>
            </section>
        </div>
    )
}
