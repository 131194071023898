import React from 'react'
import AzLogo from '../img/logoAZfooter.png'
//import AvisoLegal from '../files/CHL_Aviso_Legal_y_Términos_de_Uso_MasQueGlucosa__22062020.pdf'
import AvisoLegal from '../files/Aviso_Legal_y_Términos_de_Uso_MQG_CL.pdf'
import NotiCookies from '../files/Notificacion_sobre_Cookies_MQG CL.pdf'
import NotificacionLegalContainer from "../containers/NotificacionLegalContainer";
import {Link} from "react-router-dom";

export default function FooterLegal2() {
    return (
        <div>
            <div class="footer-bottom mt-2 pb-5">
                <div class="container">
                    <div class="d-flex flex-row align-items-center">
                        <div class="col-md-10 submenu ">

                            <Link to={"/notificacion-legal"}>Aviso Legal y Términos de Uso</Link>
                            <a href="https://www.globalprivacy.astrazeneca.com/" target="_blank">Aviso de privacidad</a>
                            <a href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/cl/es/amp-form.html?country=cl&lang=es" target="_blank">Notificación de reacciones adversas</a>
                             <a href={NotiCookies} target="_blank">Notificación sobre cookies</a>

                        </div>

                        <div className="col-md-2 "> <img className="mb-3 " src={AzLogo} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
