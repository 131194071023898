import React from 'react'
import Wikicardio from '../components/Wikicardio'

import Sociedades from '../components/Sociedades'
import FooterNav from '../components/FooterNav'
import FooterLegal from '../components/FooterLegal'
import FooterLegal2 from './FooterLegal2'

export default function Footer() {
    return (
        <div>
            <footer>
                {/* <Wikicardio/> */}
                <Sociedades />
                <FooterNav />
                <FooterLegal />
                <FooterLegal2 />
            </footer>
        </div>
    )
}
