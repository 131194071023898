import React from "react";
import AddToCalendar from "react-add-to-calendar";
import ReactHtmlParser from "react-html-parser";

export default function AgregaralCalendario(props) {

    let event = {
        title: props.title,
        description: props.detail,
        location: props.place,
        startTime: props.start,
        endTime: props.end
    };

    let icon = { 'calendar-plus-o': 'left' };


    return (
        <AddToCalendar
            event={event}
            buttonTemplate={icon}
            displayItemIcons={true}
            buttonLabel="AGENDAR"
        />
    );
}
