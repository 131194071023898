import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactHtmlParser from 'react-html-parser'

import { API_MATERIAL_NOTES_FILES } from '../store/constants'




export default function MultiCarousel(props) {

    console.log(props.notes)


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            className="multi-carousel"
            //   swipeable={false}
            //   draggable={false}
            //   showDots={true}
            responsive={responsive}
            //   ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            //   keyBoardControl={true}
            //   customTransition="all .5"
            //   transitionDuration={500}
            //   containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
        //   deviceType={this.props.deviceType}
        //   dotListClass="custom-dot-list-style"
        //   itemClass="carousel-item-padding-40-px"
        >
            {props.notes.map(note => (
                <div className="nota" >
                    <Link to={`/espacio-diabetes/${note['id_note']}`} >
                        <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                    </Link>
                    <Link to={`/espacio-diabetes/${note['id_note']}`} >
                        <h5>{note['title']}</h5>
                    </Link>
                    <p className="detail" >{ReactHtmlParser(note['detail'].slice(0, 150))}...</p>
                </div>
            ))}

        </Carousel>
    )
}