import React from 'react'
import { Link } from 'react-router-dom'
import Actividades4x4 from '../components/Actividades4x4'
import ActividadesRelacionadasmobile from './ActividadesRelacionadasmobile'

export default function ActividadesRelacionadas() {
    return (
        <div>
            <ActividadesRelacionadasmobile />
            <div className="container mt-5 mb-5 hide">
                <a style={{ color: "#3B3B3B", fontWeight: 'bold ' }} href="/calendario-de-actividades" to><h2 className="mb-5">MÁS ACTIVIDADES QUE TE PUEDEN INTERESAR</h2></a>
                <hr />
                <Actividades4x4
                    col={"6"}
                />
            </div>
        </div>
    )
}
