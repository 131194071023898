import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import { API_MATERIAL, URL } from '../store/constants';
import {useLocation} from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux';
import Separador from '../img/hr-100-celeste.png'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet';


export default function DoctoresContainer() {

    const bodyClass = useSelector((state)=> state.menu.bodyClass);
    console.log(bodyClass);

    const doctors = useSelector((state) => state.doctorsReducer.doctors, shallowEqual);
    console.log(doctors);

    let location = useLocation();
    
    const doctor = doctors.filter((doctor) => `/doctor/${doctor.id_author}`== location.pathname)[0]
    console.log(location.pathname);


    return (

        
        <div className={bodyClass}>
            <Helmet>
                <title>Doctores | Mas Que Glucosa</title>
                <meta name="description" content={doctor.title}></meta>
            </Helmet>
            <Navbar/>
            
            <SideNav/>
            <div className="container">
                <section>
                    <div className="row">
                        <div className="col-md-4"> 
                            <img src={`${API_MATERIAL}/doctors/media/${doctor['file']}`} className="mb-2"/> 
                            <img src={Separador}className="mb-2"/>
                            <h1>{doctor.title}</h1>
                            <h4>{doctor.subtitle}</h4> 
                        </div>

                        <div className="col-md-8">
                            {ReactHtmlParser(doctor.Description)}
                        </div>
                    </div>
                </section>  
            </div>
            
            <Footer/>
        </div>
    )
}
