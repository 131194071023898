import React from 'react'

export default function NotificacionLegal(){
    return (
        <div className={"container py-lg-5 py-3"}>
            <p className={"my-3"}>La presente página (juntamente con los documentos referenciados en la misma) establece los términos que rigen el uso que usted puede hacer de nuestro sitio web (en adelante “<b>Sitio web</b>”), ya sea como invitado o usuario registrado (en adelante, “<b>Términos de uso</b>”). Le instamos a leer estos Términos de uso detenidamente antes de usar el Sitio web. </p>
            <p className={"my-3"}>La visita, el acceso, la participación, el suministro de datos, la utilización y/o la navegación en el Sitio web, manifiesta que ha leído y acepta estos Términos de uso y se compromete a cumplirlos. En caso de no estar de acuerdo con estos Términos de uso, rogamos que se abstenga de usar nuestro Sitio web.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Información sobre Nosotros</h2>
            <p className={"pb-3"}>Este Sitio web es titularidad de AstraZeneca S.A. (en adelante, “nosotros” o “AstraZeneca”), una sociedad constituida en la República de Chile, RUT N°76.447.530-5 y con domicilio en Isidora Goyenechea 3477, Piso 2°, Las Condes, Santiago, Chile, o bien de uno de sus Afiliados. AstraZeneca SA. Pertenece al Grupo AstraZeneca, cuyas principales sedes comerciales pueden consultarse <a href="https://www.astrazeneca.com/global/en/global.html" target={"_blank"} rel={"nofollow noreferrer"} referrerPolicy={"no-referrer"}>aquí</a></p>
            <h2 className={"h4 text-blue mt-lg-5"}>Acceso al Sitio web </h2>
            <p className={"mb-3"}>El acceso a nuestro Sitio web está permitido con carácter temporal, reservándonos el derecho a retirar o modificar el servicio prestado a través de nuestro Sitio web sin previo aviso (ver más abajo). No asumimos ninguna responsabilidad por la falta de disponibilidad de nuestro Sitio web, independientemente del momento y la duración de dicha indisponibilidad. De vez en cuando, podremos restringir el acceso a algunas secciones de nuestro Sitio web, o al sitio web entero, a aquellos usuarios que se hayan registrado con nosotros.</p>
            <p className={"my-3"}>En el supuesto de que usted haya elegido, o nosotros le hayamos facilitado, un código de identificación de usuario, clave de acceso o cualquier otra información relacionada con nuestros procedimientos de seguridad, deberá conservar dicha información de manera confidencial y no revelarla a terceros. Nos reservamos el derecho a deshabilitar, en cualquier momento, cualquier código de identificación de usuario o clave de acceso, independientemente de que usted los haya elegido o nosotros se lo hayamos facilitado, en caso de que, según nuestro criterio, detectemos algún incumplimiento de las disposiciones de estos Términos de uso. Usted deberá notificarnos inmediatamente cualquier revelación o uso no autorizado de su código de identificación de usuario y/o clave de acceso, en cuanto tenga conocimiento de ello.</p>
            <p className={"my-3"}>Usted es responsable de realizar todas aquellas gestiones necesarias para obtener acceso al Sitio web. Por otro lado, también es responsable de que cualquier persona que acceda a este Sitio web utilizando su conexión a Internet haya sido previamente informada sobre estos Términos de uso y cumpla con los mismos.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Sección Exclusiva para Profesionales de la Salud</h2>
            <p className={"mb-3"}>El Sitio web puede contener una sección de acceso restringido y exclusivo para Profesionales de la Salud facultados para prescribir o dispensar medicamentos en la República Argentina a fin de brindarles un marco de referencia sobre información técnica especializada relacionada con la ciencia médica. Al acceder a dicha sección, usted declara y garantiza que es un Profesional de la Salud habilitado para prescribir o dispensar medicamentos en la República de Chile.</p>
            <p className={"my-3"}>Tenga en cuenta que la información de prescripción debe guiar el uso adecuado de todos los medicamentos. Antes de recetar cualquier medicamento, los Profesionales de la Salud deben consultar la información de prescripción aprobada para dicho medicamento en su país.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Uso prohibido</h2>
            <p className={"mb-3"}>Usted solamente podrá usar el Sitio web con fines lícitos. En consecuencia, no podrá usar nuestro Sitio web:</p>
            <ul>
                <li>De ningún modo que contravenga cualquier ley o regulación local, municipal, provincial, nacional o internacional</li>
                <li>De ningún modo que sea ilícito o fraudulento, o tenga una finalidad o resultado ilícito o fraudulento</li>
                <li>Para transmitir o facilitar el envío de publicidad o material publicitario no solicitado o no autorizado, o cualquier otra forma análoga de obtención de clientes (spam).</li>
                <li>Para realizar intentos no autorizados de acceso a nuestros sistemas informáticos o las redes de terceros.</li>
                <li>Con fines comerciales</li>
            </ul>
            <p className={"my-3"}>Queda terminante prohibido: (i) copiar, modificar, adaptar, traducir, realizar ingeniería inversa, descompilar o desensamblar cualquier parte del contenido y/o del Sitio web; (ii) hacer uso del contenido en otro sitio web o entorno informático para cualquier propósito sin la autorización previa y por escrito de AstraZeneca; (iii) reproducir y/o comunicar por cualquier medio el contenido, dado que el acceso se otorga en forma personal y para uso exclusivo del usuario; (iv) interferir o interrumpir el funcionamiento del Sitio web; (v) vender, licenciar o explotar el contenido y/o cualquier tipo de acceso y/o uso del Sitio web e (vi) infringir de cualquier modo los presentes Términos de uso.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Servicios interactivos</h2>
            <p className={"mb-3"}>De vez en cuando, podremos prestar servicios interactivos en nuestro Sitio web, incluyendo, pero en ningún caso con carácter limitativo, salas de chat o tablones de anuncios. En dichos casos, le facilitaremos información clara sobre el tipo de servicio ofrecido, si dicho servicio es o no moderado y, en caso afirmativo, de qué forma se realiza la moderación (incluyendo si se trata de una moderación humana o técnica).</p>
            <p className={"my-3"}>Haremos lo posible para evaluar los posibles riesgos para los usuarios (y, en particular, los menores) ocasionados por terceros que utilizan cualquier servicio interactivo disponible en nuestro Sitio web, con la finalidad de determinar la necesidad de moderar el servicio en cuestión (incluyendo, el tipo de moderación a realizar) en función de dichos riesgos. Sin embargo, no estamos obligados a controlar, supervisar o moderar ningún servicio interactivo que prestemos y rechazamos expresamente cualquier responsabilidad por los daños y pérdidas que pudieran derivarse del uso indebido de cualquier servicio interactivo por parte de un usuario que contravenga nuestras reglas de contenido, independientemente de que el servicio sea moderado o no.</p>
            <p className={"my-3"}>El uso del servicio por un menor está sujeto al previo consentimiento otorgado por sus padres o tutores legales. Resaltamos a los padres que permitan a sus hijos usar un servicio interactivo la necesidad de que éstos últimos sean conscientes de la importancia de la seguridad en línea, ya que la moderación no es infalible. Los menores deben ser conscientes de los riesgos potenciales a los cuales pueden estar expuestos.</p>
            <p className={"my-3"}>En el supuesto de que moderemos un servicio interactivo, lo más común será que pongamos a su disposición medios de contacto con el moderador en caso de que surja alguna inquietud o problema.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Reglas de contenido</h2>
            <p className={"mb-3"}>Estas reglas de contenido se aplican a cualquier contenido y/o material que usted proporcione a través de nuestro Sitio web (contribuciones) y/o cualquier servicio interactivo asociado al mismo. Usted deberá respetar las reglas que se indican a continuación. Las reglas se aplican tanto a fragmentos de la contribución como a su conjunto.</p>
            <p className={"my-3"}>Las contribuciones deben ser exactas (cuando hagan referencia a hechos) y ser originales (en caso de que versen sobre opiniones).</p>
            <p className={"my-3"}>Las contribuciones no pueden:</p>
            <ul>
                <li>Contener material que sea difamatorio hacia terceros.</li>
                <li>Contener material que sea obsceno, ofensivo, odioso o provocativo.</li>
                <li>Promover material sexualmente explícito.</li>
                <li>Incitar a la violencia.</li>
                <li>Incitar a la discriminación basada en la raza, sexo, religión, nacionalidad, discapacidad, orientación sexual o edad.</li>
                <li>Vulnerar derechos de autor, patentes o marcas.</li>
                <li>Inducir al engaño.</li>
                <li>Suponer un incumplimiento de una obligación legal contraída con un tercero como, por ejemplo, una obligación contractual o de confidencialidad.</li>
                <li>Incitar a la realización de una actividad ilícita.</li>
                <li>Suponer una amenaza, abuso o intromisión en la intimidad de otra persona, o causar molestias, inconvenientes o ansiedad innecesaria.</li>
                <li>Acosar, molestar, humillar, asustar o molestar a terceros.</li>
                <li>Ser usadas para suplantar a otras personas o representar falsamente su identidad o vínculos con terceros.</li>
                <li>Dar la impresión de que provienen de nuestra parte cuando no sea el caso.</li>
                <li>Defender, promover o contribuir a la realización de actos ilícitos como, por ejemplo, la vulneración de derechos de autor o uso indebido de computadoras.</li>
            </ul>
            <h2 className={"h4 text-blue mt-lg-5"}>Suspensión y Terminación</h2>
            <p className={"mb-3"}>Determinaremos, según nuestro propio criterio, si se ha producido un incumplimiento de esto Términos de uso en base al uso que usted haga del Sitio web. Cuando una vulneración de estos Términos de uso haya tenido lugar, adoptaremos las medidas que consideremos apropiadas, incluyendo, pero en ningún caso con carácter limitativo, las siguientes medidas:</p>
            <ul>
                <li>Revocar su derecho a usar el Sitio web de manera inmediata, temporal o permanente.</li>
                <li>Retirar cualquier publicación o material subido en nuestro Sitio web de manera inmediata, temporal o permanente.</li>
                <li>Hacerle llegar una advertencia.</li>
                <li>Emprender acciones legales contra usted, reclamando el reembolso de todos los gastos en concepto de indemnización (incluyendo, entre otros, costes administrativos y legales) que puedan originarse como consecuencia del incumplimiento.</li>
                <li>Acciones legales adicionales.</li>
                <li>Interponer denuncia ante las autoridades policiales en caso de que lo consideremos razonablemente necesario.</li>
            </ul>
            <p className={"my-3"}>No asumimos responsabilidad alguna por las medidas que se adopten en respuesta al incumplimiento de estos Términos de uso.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Obligaciones de los Miembros</h2>
            <p className={"mb-3"}>Al darse de alta en el Sitio web como miembro, se compromete a informarnos inmediatamente sobre cualquier reclamo o acción en su contra relacionada con el uso de este Sitio web y, si es requerido para ello, a cesar en el acto objeto del reclamo. Usted se compromete a informarnos inmediatamente de cualquier cambio que afecte a los datos de su registro, haciéndose responsable de que los datos de registro se mantengan actualizados.</p>
            <p className={"my-3"}>Usted podrá dejar de ser miembro de este Sitio web en cualquier momento mediante un correo electrónico dirigido a [<a href="mailto:hola@masqueglucosa.cl">hola@masqueglucosa.cl</a>]. Deberá hacer constar claramente, en todas las comunicaciones, su nombre y, si procede, nombre de usuario y datos de registro, así como el nombre de este Sitio web.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Exclusión de responsabilidad</h2>
            <p className={"mb-3"}>Toda la información contenida en este Sitio web tiene fines educacionales e informativos exclusivamente (la “<b>Información</b>”). En ningún caso la Información deberá ser considerada como un consejo médico profesional de parte de AstraZeneca y/o de la Página web y/o de quienes eventualmente suscriban los informes, artículos, comentarios, disertaciones, exposiciones, notas, etc. que se publiquen en el Sitio web (las “<b>Publicaciones</b>”).</p>
            <p className={"my-3"}>Se deja constancia que ni AstraZeneca, ni el Sitio web ni quienes suscriban las Publicaciones realizan, a través de este Sitio web, actividades de asesoramiento, ni de diagnóstico, ni de tratamiento médico, ni de ejercicio de la medicina, sino que brindan la Información para ampliar conocimientos en materia de salud. La Información es meramente descriptiva y de índole general y no contempla, ni pretende contemplar todas las enfermedades, síntomas, malestares, condiciones físicas o psicológicas relacionadas con la salud y no deberá ser utilizada para diagnosticar o realizar el análisis, diagnóstico o tratamiento de una enfermedad o problema de salud sin consultar previamente con un médico matriculado especialista en la patología en cuestión.</p>
            <p className={"my-3"}>Es importante que los profesionales de la salud que tengan acceso a este Sitio web consideren que todo el material aquí contenido, específicamente aquel dirigido a dichos profesionales, se incluye con el único objetivo de que cuenten con información de referencia adicional con respecto a los avances en el mundo de la medicina.</p>
            <p className={"my-3"}>A pesar de nuestros esfuerzos razonables a la hora de elaborar el presente Sitio web, no somos responsables por las decisiones tomadas por cualquier persona o entidad, independientemente del lugar en que radique su domicilio, como consecuencia, directa o indirecta, de la información contenida o accedida a través de este Sitio web, siendo indiferente si ésta ha sido facilitada por nosotros o terceros. Nada de lo contenido en este Sitio web podrá considerarse como la pretensión de AstraZeneca de proporcionar algún consejo, asesoramiento, recomendación, dar solución o respuesta final o especifica de algún problema de salud, diagnóstico, o recomendación de un tratamiento o prescripción de los productos que en el mismo se contienen y/o exhiben. Dados los constantes avances médicos y desarrollos de la medicina, la información contenida en este Sitio web puede no estar siempre completamente actualizada y, por esta razón, dicha información se facilita “<i>TAL CUAL</i>” y “<i>EN FUNCIÓN DE SU DISPONIBILIDAD</i>”.</p>
            <p className={"my-3"}>NO OFRECEMOS NINGUNA GARANTÍA, REPRESENTACIÓN O COMPROMISO, SEA EXPRESA O TÁCITAMENTE, EN RELACIÓN A CUALQUIER CONTENIDO DE ESTE SITIO WEB (ESPECÍFICAMENTE, PERO EN NINGÚN CASO CON CARÁCTER LIMITATIVO, EN RELACIÓN A LA ACTUALIDAD, VIGENCIA, EXACTITUD, INTEGRIDAD O IDONEIDAD PARA CUALQUIER FINALIDAD CONCRETA DE DICHO CONTENIDO, ASÍ COMO TAMPOCO ACERCA DE QUE LOS RESULTADOS OBTENIDOS EN BASE AL USO DEL SITIO WEB ESTARÁN LIBRES DE ERROR O SERÁN FIABLES).</p>
            <p className={"my-3"}>Usted acepta que el uso de la información obtenida o descargada desde o a través de este Sitio web se realiza a su propia discreción y riesgo. Algunas jurisdicciones no permiten la exclusión de garantías implícitas; por tanto, lo indicado anteriormente no se aplicará en dichas jurisdicciones. Cualquier información médica facilitada en este Sitio web no pretende sustituir el asesoramiento médico especializado, tratamiento y prescripción de los profesionales de la salud y no podemos responder aquellos correos electrónicos no solicitados relacionados con asuntos personales de salud. La información sobre los productos facilitados en este Sitio web puede variar dependiendo del país. Los pacientes y profesionales sanitarios deberán consultar con los recursos médicos de su propia localidad y las autoridades de control pertinentes para obtener información apropiada relacionada con su país. DEBE CONSULTAR CON UN PROFESIONAL DE LA SALUD DEBIDAMENTE CUALIFICADO ANTES DE TOMAR CUALQUIER DECISIÓN BASADA EN ALGUNA INFORMACIÓN CONTENIDA EN ESTE SITIO WEB.</p>
            <p className={"my-3"}>Nada de lo contenido en este Sitio web podrá considerarse como una invitación a invertir o negociar con acciones, ADRs o cualquier otro tipo de valores en AstraZeneca. Los resultados reales o los desarrollos pueden ser materialmente diferentes a cualquier previsión, opinión o expectativa expresada en este Sitio web. El desarrollo de ejercicios anteriores no debe servir como indicativo sobre el futuro rendimiento de los mismos.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Nuestra Responsabilidad</h2>
            <p className="mb-3">En la medida en que la Ley lo permita, nosotros, otros miembros de nuestro Grupo AstraZeneca (tal como se define a continuación) y terceros asociados a nosotros, quedamos expresamente excluidos de:</p>
            <ul>
                <li>Todas las condiciones, garantías y otros términos que podrían estar implícitos por la ley, el derecho común o ley de equidad; y</li>
                <li>Cualquier responsabilidad por pérdidas o daños directos o indirectos o consecuentes sufridos por cualquier usuario en conexión con nuestro Sitio web o su uso, su inhabilidad a usarlo, o los resultados del uso de nuestro Sitio web, cualquier sitio web relacionado con éste o material publicado en el mismo, incluyendo, pero en ningún caso con carácter limitativo, cualquier responsabilidad por (i) pérdida de ingresos o beneficios, (ii) pérdida de oportunidades de negocio, (iii) pérdida de ganancias, (iv) pérdida anticipada de ahorros, (v) pérdida de datos, (vi) pérdida reputacional, y (vii) por cualquier otra pérdida o daño de cualquier tipo, independientemente de que pudiera producirse como consecuencia de culpa (incluyendo negligencia), incumplimiento contractual u otros, incluso si su producción fuera predecible.</li>
            </ul>
                <p className={"my-3"}>Lo anterior no afecta nuestra responsabilidad por dolo, muerte o daños físicos derivados de nuestra negligencia, así como por cualquier otra responsabilidad que no pueda excluirse o limitarse según la ley aplicable.</p>
                <p className={"my-3"}>No garantizamos que las funciones contenidas en este Sitio web estarán disponibles de forma ininterrumpida, que estarán exentas de errores o que, en caso de contener errores, éstos serán subsanados. Tampoco garantizamos que este Sitio web o el servidor que lo alberga expresamente estarán libres de virus o fallos.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Derechos de Propiedad Intelectual</h2>
            <p className={"mb-3"}>Los derechos de propiedad intelectual de este Sitio web y los relacionados con el material publicado en él, incluyendo, pero en ningún caso con carácter limitativo, documentos, archivos, texto, imágenes, Fuentes RSS, archivos de audio, podcasts, archivos de video, tutoriales flash, gráficos, dispositivos y códigos contenidos en el mismo, así como la apariencia del sitio web, son nuestros o de terceros licenciadores. Dichos trabajos están protegidos por la legislación sobre derechos de autor aplicable, quedando reservados.</p>
            <p className={"my-3"}>Usted está autorizado a realizar una única copia e imprimir extractos o documentos de este Sitio web (exceptuando aquel contenido titularidad de terceros e identificado como tal) para un uso personal y no comercial, siempre que dicha copia o impresión retenga todos los derechos de autor u otros avisos sobre derechos de propiedad y exclusión de responsabilidad contenidos en los mismos. Todos los nombres, signos distintivos y marcas titularidad de AstraZeneca no pueden ser usados o reproducidos sin el previo consentimiento escrito de ésta. Nuestra condición (y la de cualquier contribuidor identificado) de autor del material contenido en nuestro Sitio web debe ser siempre reconocida.</p>
            <p className={"my-3"}>Además de lo indicado anteriormente, la reproducción parcial o total de los contenidos del Sitio web en cualquier soporte, incluyendo la composición, la creación de trabajos derivados basados en este Sitio web y/o su contenido, así como su incorporación a otros sitios web o servicios de sistemas de recuperación o publicación, quedan prohibidos. Puede utilizar cualquier diagrama, ilustración, fotografía, vídeo o secuencia de audio o gráfico separadamente de cualquier texto que los acompañe. Ningún enlace contenido en este Sitio web puede ser incluido en cualquier otro sitio web sin el previo permiso escrito otorgado por AstraZeneca.</p>
            <p className={"my-3"}>No podemos garantizar que usted tiene derecho a usar el contenido disponible en este Sitio web cuando el mismo es titularidad de terceros. Por tanto, deberá obtener permiso de éstos antes usar y/o descargar dicho contenido. El contenido que esté protegido por derechos de autor no puede ser modificado ni pueden alterarse los avisos de atribución de autoría o los avisos de derechos de autor que aparezcan juntamente con dicho contenido, a menos que se haya obtenido la previa autorización expresa para ello.</p>
            <p className={"my-3"}>A excepción de los permisos indicados anteriormente, nada de lo contenido en este Sitio web debe ser interpretado como un otorgamiento de cualquier otro derecho o licencia. Todos los nombres de producto que aparecen en este Sitio web son marcas titularidad de AstraZeneca o sus Afiliados, exceptuando aquellas marcas que aparecen identificadas como titularidad de otras empresas, quedando todos los derechos reservados.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Derecho a usar la Información facilitada por el Usuario</h2>
            <p className={"mb-3"}>En caso de que usted facilite cualquier material para ser incluido en este Sitio web (incluyendo, pero en ningún caso con carácter limitativo, información personal, conocimientos, comentarios, ideas, preguntas, técnicas, abstractos o similares), acepta que (i) dicho material no tendrá la consideración de confidencial, (ii) dicho material no tendrá la consideración de registrado; y (iii) usted nos concede una licencia irrevocable, a nivel mundial, perpetua, libre de regalías y no exclusiva para usar, divulgar, copiar, modificar, adaptar, publicar y traducir todo o parte de dicho material para cualquier finalidad sin restricción alguna. Nos reservamos el derecho, según nuestro propio criterio, a retirar o eliminar, sin previo aviso, cualquier material que facilite para su inclusión en este Sitio web. Usted nos autoriza a difundir su identidad a cualquier tercero que alegue que el material publicado o subido por usted en nuestro Sitio web constituye una infracción de sus derechos de propiedad intelectual o protección de datos. Sus datos personales serán tratados según nuestro Aviso de privacidad.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Virus, Piratería y otras Infracciones</h2>
            <p className={"mb-3"}>No debe hacer un mal uso de nuestro Sitio web mediante la introducción intencionada de virus, troyanos, gusanos, bombas lógicas o cualquier otro material que sea malicioso o tecnológicamente dañino. No debe intentar, de manera no autorizada, acceder a nuestro Sitio web, el servidor en el cual el Sitio web está almacenado o cualquier otro servidor, las computadoras o base de datos conectados a nuestro Sitio web. No debe atacar nuestro sitio web por medio de un ataque de denegación de servicio o ataque de denegación distribuido de servicio.</p>
            <p className={"my-3"}>En caso de incumplir la presente disposición, usted podría estar cometiendo un delito. Denunciaremos ante las autoridades policiales correspondientes cualquier incumplimiento detectado y cooperaremos con las mismas facilitándoles su identidad si fuera necesario. En caso de que tenga lugar dicho incumplimiento, su derecho a usar nuestro Sitio web quedará automáticamente resuelto.</p>
            <p className={"my-3"}>No seremos responsable ante cualquier pérdida o daño causado por un ataque de denegación distribuido de servicio, virus o cualquier otro material tecnológico dañino que pueda infectar a su equipo informático, programas, datos o cualquier material de su propiedad como consecuencia del uso que haga de nuestro Sitio web o la descarga de cualquier material publicado en el mismo o en cualquier sitio web asociado al mismo. De conformidad con las buenas prácticas en el ámbito informático, le recomendamos realizar un análisis de todos los materiales y/o contenidos accesibles y/o descargables desde este Sitio web con la finalidad de detectar cualquier virus, usando a tal efecto cualquier programa antivirus actualizado disponible en el mercado.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Enlaces a nuestro Sitio web</h2>
            <p className={"mb-3"}>Usted no puede establecer enlaces que dirijan a nuestro Sitio web sin previamente haber obtenido nuestro consentimiento por escrito. Una vez obtenido el consentimiento, cualquier enlace debe presentarse de manera justa y lícita, no pudiendo éste dañar nuestra reputación y suponer un aprovechamiento indebido de la misma. Usted no puede establecer un enlace desde un sitio web que no sea de su titularidad.</p>
            <p className={"my-3"}>Este Sitio web no debe ser vinculado a ningún otro sitio web, ni pueden crearse enlaces a cualquier sección de nuestro Sitio web distinta de la página principal del mismo, salvo que exista el consentimiento indicado anteriormente. Nos reservamos el derecho a retirar sin previo aviso cualquier consentimiento otorgado en relación a los enlaces. El Sitio web en el cual se incluirán los enlaces debe cumplir con el presente aviso legal y términos de uso.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Sitios web y Contenido de Terceros</h2>
            <p className={"mb-3"}>Este Sitio web puede incluir contenidos titularidad de terceros (por ejemplo, artículos, biblioteca de imágenes, fuentes de datos y abstractos) así como enlaces o hipervínculos a sitios web titularidad de terceros. Facilitamos dicho contenido y enlaces de terceros como cortesía a nuestros usuarios. Los enlaces se facilitan sólo a efectos informativos. No controlamos los sitios web de terceros o el contenido indicado anteriormente accesible a través de este Sitio web o disponible en el mismo. No promocionamos, patrocinamos o recomendamos los sitios web o el contenido de terceros y, por tanto, no somos responsables por los mismos ni por la disponibilidad de éstos. Específicamente, no aceptamos ninguna responsabilidad derivada de cualquier reclamo en relación a cualquier contenido titularidad de terceros (sea publicado en esta página o en cualquier otro Sitio web) que infrinja derechos de propiedad intelectual de cualquier persona, o responsabilidad derivada de cualquier información u opinión incluida en el sitio web de terceros o en su contenido.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Modificaciones del Sitio web</h2>
            <p className={"mb-3"}>Nos reservamos el derecho a modificar cualquier parte de este Sitio web o cualquier aviso legal y términos de uso en cualquier momento y sin previo aviso. Cualquier modificación de un aviso legal o términos de uso entrará en vigor en el siguiente acceso que realice a este Sitio web. Tiene la responsabilidad de revisar el presente aviso legal y términos de uso en cada acceso para conocer los cambios realizados, ya que éstos son obligatorios. Sin perjuicio de lo anterior, no estamos obligados a mantener este Sitio web actualizado. En caso de que fuera necesario, podemos suspender el acceso a este Sitio web o impedirlo indefinidamente</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Código de Buenas Prácticas de Marketing de la Federación Internacional de Asociaciones de la Industria Farmacéutica (IFPMA)</h2>
            <p className={"mb-3"}>Cualquier contenido promocional incluido en este Sitio web ha sido elaborado según el Código de Buenas Prácticas de Marketing de la Federación Internacional de Asociaciones de la Industria Farmacéutica (IFPMA). Al usar este Sitio web, acepta que cualquier cuestión que pueda tener en relación a dicho contenido sea previamente dirigido a nosotros. En caso de no estar satisfecho con nuestra contestación, podrá presentar una reclamación ante IFPMA, ubicada en Ginebra, Suiza. Cualquier reclamación relacionada con el contenido promocional específico para un país puede alternativamente ser presentado ante la Asociación de la Industria Farmacéutica concerniente a dicho país.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Jurisdicción y legislación Aplicable</h2>
            <p className={"mb-3"}>Los Tribunales Ordinarios de la Ciudad Autónoma de Buenos Aires, Argentina tendrán jurisdicción exclusiva sobre cualquier reclamación derivada o relacionada con su acceso a nuestro Sitio web, aunque nos reservamos el derecho a interponer cualquier acción contra usted por incumplimiento de las presentes condiciones en su país de residencia o en cualquier otro país pertinente. Los presentes Términos de uso se interpretarán según la legislación Chilena.</p>
            <h2 className={"h4 text-blue mt-lg-5"}>Contacto</h2>
            <p className={"mb-3"}>Puede contactarnos mediante un correo electrónico dirigido a AstraZeneca [<a href="mailto:hola@masqueglucosa.cl">hola@masqueglucosa.cl</a>]. En los presentes Términos de uso, “Afiliados” significa cualquier empresa o entidad comercial que controle a AstraZeneca, esté controlada por AstraZeneca o bajo común control junto con AstraZeneca. A efectos de la presente definición, “control” significa la propiedad directa o indirecta de (i) el cincuenta por ciento (50%) o más de las participaciones con derecho a voto; o (ii) el cincuenta por ciento (50%) o más en la participación de los beneficios o ingresos, en aquellos supuestos en que la entidad comercial tenga una forma distinta a una empresa; o (iii) en caso de asociación, cualquier interés semejante en el socio general.</p>
        </div>
    )
}