import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import fbicon from '../img/icono-fb-white.png'
import igicon from '../img/icono-ig-white.png'
import { Link } from 'react-router-dom'
import { closeSidebar } from '../store/actions/menu'
import shareWp from '../img/share-wp.png'

export default function SideNav() {

    const [sidebar, setSidebar] = useState(false)

    const dispatch = useDispatch();
    const handleCloseSidebar = () => {
        dispatch(closeSidebar())
    }
    //<li><a className="sub" href="/#educacion" onClick={handleCloseSidebar} aria-current="page">Educación</a></li>
    return (
        <div>
            <nav className="menu-principal" id="navigation">
                <div className="menu-principal-container">
                    <div className="menu-items-container">
                        <h2>MENÚ</h2>
                        <ul className="menu-slider-right">
                            <li><a href="/dia-mundial-diabetes" className="sub" onClick={handleCloseSidebar}>Día Mundial de la Diabetes</a></li>
                            <li><a className="sub" href="/#mas-que-glucosa" onClick={handleCloseSidebar} aria-current="page">Sobre La Campaña</a></li>
                            <li ><Link className="sub" to="/espacio-diabetes" onClick={handleCloseSidebar}>Educación</Link></li>
                            {/* <li><a className="sub" href="/" onClick={handleCloseSidebar} aria-current="page">Test de riesgo</a></li> */}
                            {/* <li><a className="sub" href="/calendario-de-actividades" onClick={handleCloseSidebar} aria-current="page">Calendario de actividades</a></li> */}
                            <li><a className="sub" href="/#consejos" onClick={handleCloseSidebar} aria-current="page">#MQGTips</a></li>
                            <li><a className="sub" href="/test-de-riesgo" onClick={handleCloseSidebar} aria-current="page">Test de riesgo</a></li>
                            <li><Link className="sub" onClick={handleCloseSidebar} to="/contacto/">Contáctanos</Link></li>
                            {/*<li><Link className="sub" onClick={handleCloseSidebar} to="/calendario-de-actividades">Calendario de Actividades</Link></li>*/}
                            {/* <li className="d-flex d-sm-none">
                                <a className="enfermeria d-flex align-items-center " href="https://api.whatsapp.com/send?phone=5491163607927&text=Hola%20Más%20Que%20Glucosa" target="_blank"> <img src={shareWp} className="mr-3" />
                                    <p>Enfermería</p>
                                </a>
                            </li> */}
                            <li className="d-flex d-sm-none">
                                <a className="enfermeria d-flex align-items-center " href="https://www.azmed.cl" target="_blank">
                                    <p>Profesionales de la salud</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="menu-redes mt-5">
                        <a href="https://www.instagram.com/masqueglucosachile/" target="_blank"><img src={igicon} alt="Icono Instagram" /></a>
                        <a href="https://www.facebook.com/masqueglucosachile" target=" _blank"><img src={fbicon} alt="Icono Facebook" /></a>
                    </div>
                </div>
            </nav>
        </div>
    )
}
