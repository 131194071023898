import {
    FETCH_NOTES
  } from "../constants";
  
  const initialState = {
    notes: [],
  };





export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTES:
            return Object.assign({}, state, {
                notes: action.notes
            });
        default:
            return state;
    }
}