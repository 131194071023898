import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import TuEspacioDeDiabetesNota from '../components/TuEspacioDeDiabetesNota'
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet'


export default function TuEspacioDeDiabetesNotaContainer() {
    const bodyClass = useSelector((state) => state.menu.bodyClass);



    return (



        <div id="espacio-diabetes-interna" className={bodyClass}>
            {/* <Helmet>
                <title>{`${note.title}| Mas Que Glucosa`}</title>
                <meta name="description" content={note.title}></meta>
              
                <meta name="title" content={`${note.title}| Mas Que Glucosa`}></meta>
                <meta name="description" content="El diagnóstico se realiza a través de una historia clínica, examen físico y laboratorio."></meta>
           
            </Helmet> */}
            <Navbar />

            <SideNav />

            <TuEspacioDeDiabetesNota />


            <Footer />
        </div>
    )
}
