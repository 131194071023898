import React from 'react'

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 45,
        width: '100%',
        backgroundColor: "#FFFFFF",
        border: '1px solid #05A9FD',
        borderRadius: 50,
        marginBottom: 50
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        padding: 5,
        color: '#05A9FD',
        fontWeight: 'bold',
        display: 'flex',
        placeContent: 'center',
        letterSpacing: '2px'
    }

    return (
        <div>
            <span style={labelStyles}>{`${completed}% completado`}</span>
            <div style={containerStyles}>
                <div className="barra-progreso" style={fillerStyles}>

                </div>
            </div>
        </div>
    );
};

export default ProgressBar;