import React from 'react'
import Testfoto from '../img/mano.png'


export default function BannerTest() {




    return (
        <div>
            <div className="container">
                <div id="header-interna">
                    <p style={{ textTransform: 'uppercase' }} className="miga-pan" >TEST DE RIESGO</p>
                    <h3>CONOZCA SU RIESGO DE DIABETES TIPO 2</h3>
                </div>
            </div>
            <div className="container-fluid test-riesgo gradiente-test" >

                <div className="container  d-flex justify-content-around ">

                    <div className="test ">



                        <img className="manos-mobile" src={Testfoto} />


                        <div >
                            <h4>La Federación Internacional de Diabetes (FID) estima que hasta 212 millones de personas, o la mitad de todos los adultos que viven actualmente con diabetes, no están diagnosticados. <br /></h4>


                            <p>La mayoría de ellos tienen diabetes tipo 2. La FID ha creado un recurso de evaluación en línea de riesgo de diabetes que tiene como objetivo predecir el riesgo individual de desarrollar diabetes tipo 2 durante los siguientes diez años. La evaluación está basada en el Cuestionario de puntuación de riesgo de diabetes de Finlandia (FINRISC, por sus siglas en inglés) desarrollado y diseñado por Prof. Adj. Jaana Lindstrom y Prof. Jaakko Tuomilehto del Instituto Nacional de Salud y Bienestar, Helsinki, Finlandia. <a href="#referencia" className="text-white"><sup>1</sup></a></p>

                        </div>
                    </div>


                    <img className="manos" src={Testfoto} />

                </div>
                {/* <div className="d-flex justify-content-center">
    <button className="comenzar-test">COMENZAR EL TEST</button>
</div> */}


            </div>
        </div>
    )
}
