
import {
    SET_MENU_CLASS
} from '../constants'

export const setMenuClass = data => ({
    type: SET_MENU_CLASS,
    data
})



export const closeSidebar = () => dispatch => {
    console.log('closeSidebar')
    dispatch(setMenuClass({
        homeClass: 'home',
        internaClass: 'interna',
        overlayClass: 'overlay',
        buttonTogglerClass: 'navbar-toggler',
        menuClass: 'menu-principal',
        bodyClass:'',
        buttonActiveClass:'',
        enfermeriaNot: '',

    }))
}

export const toggleMenu = (bodyClass, buttonActiveClass) => dispatch => {
    if (bodyClass === '' && buttonActiveClass ==='' ){
        dispatch(setMenuClass({
            bodyClass:'nav-open',
            buttonActiveClass:'is-active',
            enfermeriaNot: 'enfermeria-not',

        }))
    } else {
        dispatch(setMenuClass({
            bodyClass:'',
            buttonActiveClass:'',
            enfermeriaNot: '',
        }))
    }
}