import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

import CarouselResponsive from './CarouselResponsive'

// //ARRAY DE NOTAS HARDCKODEADO
// import { notes } from '../tuEspacioDiabetes'

//IMG
import IconoMas from '../img/icono-mas.png'

// API RUTA IMAGENES
import { API_MATERIAL_NOTES_FILES } from '../store/constants'


export default function TuEspacioDiabetesSectionInHome() {

    const notes = useSelector(state => state.notesReducer.notes, shallowEqual)

    console.log(notes)

    return (
        <div>
            <section id="espacio-diabetes-in-home">
                <div className="container">
                    <div className="row titles w-100">
                        <div className="title-note col-6">
                            <h3>TU ESPACIO EDUCATIVO</h3>
                            <div>
                                <Link to="/espacio-diabetes" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g transform="translate(-1096.5 -1336.5)">
                                            <g transform="translate(1093.5 1333.5)">
                                                <path d="M7.5,4.5h21a3,3,0,0,1,3,3v21a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                <path d="M18,12V24" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                <path d="M12,18H24" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            </g>
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <Link to="/espacio-diabetes" className={"col-6 d-flex"}>
                            <div className="title-notas text-right ml-md-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g transform="translate(-1096.5 -1336.5)">
                                        <g transform="translate(1093.5 1333.5)">
                                            <path d="M7.5,4.5h21a3,3,0,0,1,3,3v21a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path d="M18,12V24" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                            <path d="M12,18H24" fill="none" stroke="#fc9d4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </g>
                                    </g>
                                </svg>
                                <h3  className={"yellow-text"}>NOTAS</h3>
                            </div>
                        </Link>

                    </div>
                    <CarouselResponsive
                        arrayNotes={notes}
                        home={true}
                    />
                    <div className="row desktop-notes">
                        {notes.length > 0 && (
                            <div className="col-md-6 nota-principal">
                                <Link to={`/espacio-diabetes/${notes[0]['id_note']}`}>
                                    <h4>{notes[0]["title"]}</h4>
                                </Link>
                                <Link to={`/espacio-diabetes/${notes[0]['id_note']}`}>

                                    <img src={`${API_MATERIAL_NOTES_FILES}${notes[0]["file_desktop"]}`} alt="" />
                                </Link>

                            </div>
                        )}
                        {notes.length > 0 && (
                            <div className="col-md-6 otras-notas">
                                <div className="title-notas-mobile">
                                    <img src={IconoMas} alt="" /><h3>NOTAS</h3>
                                </div>
                                {notes.slice(1, 4).map(note => (
                                    <div className="d-flex nota" >
                                        <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                            <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                                        </Link>

                                        <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                            <h5>{note['title']}</h5>
                                        </Link>

                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </div>
            </section>
        </div>
    )
}