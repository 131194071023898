export const URL = window.location.origin;


/**
 *  API PROD
 * @type {string}
 */
export const API = 'https://api.masqueglucosa.cl/api/'


/**
 * API QA
 * @type {string}
 */
/*export const API = 'https://api-qa.masqueglucosa.cl/api/';*/

/**
 * fetch sliders home
 * @type {string}
 */
export const FETCH_SLIDERS = 'FETCH_SLIDERS'

/**
 * set menu class
 * @type {string}
 */
export const SET_MENU_CLASS = 'SET_MENU_CLASS'

/**
 * API materials PROD
 * @type {string}
 */
export const API_MATERIAL = 'http://backend.masqueglucosa.cl/material'
export const API_BACKEND = 'http://backend.masqueglucosa.cl/'
export const API_MATERIAL_SLIDERS = 'http://backend.masqueglucosa.cl/material/sections_sliders/media/'
export const API_MATERIAL_NOTES_FILES = 'http://backend.masqueglucosa.cl/material/notes/media/'

/**
 * API Materials QA
 * @type {string}
 */
/*export const API_MATERIAL = 'http://backend-qa.masqueglucosa.cl/material'
export const API_BACKEND = 'http://backend-qa.masqueglucosa.cl/'
export const API_MATERIAL_SLIDERS = 'http://backend-qa.masqueglucosa.cl/material/sections_sliders/media/'
export const API_MATERIAL_NOTES_FILES = 'http://backend-qa.masqueglucosa.cl/material/notes/media/'*/

/**
 * fetch notas educación
 * @type {string}
 */
export const FETCH_NOTES = 'FETCH_NOTES';

/**
 * fetch tips
 * @type {string}
 */
export const FETCH_DIETS = 'FETCH_DIETS';

/**
 * fetch doctors
 * @type {string}
 */
export const FETCH_DOCTORS = 'FETCH_DOCTORS';

/**
 * fetch events
 * @type {string}
 */
export const FETCH_EVENTS = 'FETCH_EVENTS';