import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import { useSelector, shallowEqual } from 'react-redux';
import Noticia from '../components/Noticia'
import NoticiasRandom from '../components/NoticiasRandom'
import { Helmet } from 'react-helmet'
import {useLocation} from 'react-router-dom'


export default function EducacionContainer() {
    const bodyClass = useSelector((state)=> state.menu.bodyClass);
    console.log(bodyClass);
    const notes = useSelector((state) => state.notesReducer.notes, shallowEqual);
    console.log(notes);
    let location = useLocation();
    const note = notes.filter((note) => `/educacion/${note.id_note}`== location.pathname)[0]
    
    console.log(location.pathname);

    return (

        

        <div className={bodyClass}>
             <Helmet>
                <title>{`${note.title}| Mas Que Glucosa`}</title>
                <meta name="description" content={note.title}></meta>
              
                <meta name="title" content={`${note.title}| Mas Que Glucosa`}></meta>
                <meta name="description" content="El diagnóstico se realiza a través de una historia clínica, examen físico y laboratorio."></meta>
           
            </Helmet>
            <Navbar/>
            
            <SideNav/>
            <section className="header-interna">
                 <div className="container">
                    <h6>MÁS QUE GLUCOSA / EDUCACIÓN</h6>
                    <h1>¿Querés saber más de DIABETES?</h1> 
                </div>
            </section>
            <Noticia/>
            <NoticiasRandom/>
           
            <Footer/>
        </div>
    )
}
