import React from 'react'
import Separador from '../img/consejos-utiles-barra.png'

import MasCeleste from '../img/icono-mas.png'
import { Link } from "react-router-dom";
import Dieta1 from '../img/cover-ejercicio.jpg'
import { API_MATERIAL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser'
import Carousel from "react-multi-carousel";


import { useSelector, shallowEqual } from 'react-redux';
import Tips from './Tips'
import Slider from 'react-slick';


export default function ConsejosUtiles() {

    const diets = useSelector((state) => state.dietsReducer.diets, shallowEqual);
    const settings = {
        arrows: true,
        slidesToShow: 2,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    }


    return (
        <div id="consejos">
            <section id="consejos">
                <div className="container ">

                    <div className="">
                        <header className="col seccion mb-5">
                            <h3 style={{ marginBottom: '1rem' }} >CONSEJOS ÚTILES</h3> <img src={Separador} alt="Separador horizontal" />
                            <h4 className="mb-5 font-weight-bold">Nutrición y ejercicio físico</h4>

                        </header>

                        <div className="swiper-container sw-consejos">
                            {diets && (
                                <Slider {...settings} className="swiper-wrapper">

                                    {diets.map((consejo) => (
                                        <Link className=" sw-consejo " to={`/consejos/${consejo['slug']}`} >
                                            <div className="cover gradient" style={{ backgroundImage: `url(${`${API_MATERIAL}/diets/media/${consejo['file']}`})` }}> </div>
                                            <div className="data">
                                                <hr />
                                                <h5>{consejo['title']}</h5>
                                                <p>{ReactHtmlParser(consejo['description'])}</p>
                                            </div> <img src={MasCeleste} className="mas" alt="Leer Mas" />
                                        </Link>
                                    ))}


                                </Slider>
                            )}
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}