import React from 'react'
import { API_MATERIAL, URL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux';

import SeparadorLinea from '../img/linea-1.png'
import SeparadorLinea2 from '../img/linea-2.png'
import SeparadorLinea3 from '../img/linea-3.png'



export default function Almuerzo(props) {

    const diets = useSelector((state) => state.dietsReducer.diets, shallowEqual);
    console.log(props.diet);

    let location = useLocation();

    // const diet = diets.filter((diet) => `/tips/${diet.id_diet}` == location.pathname)[0]
    // console.log(location.pathname);

    return (
        <div>
            <section id="ayc" class="">

                <div class="container">

                    <h3>ALMUERZOS Y CENAS</h3>
                    {props.diet && (
                        <div class="row">




                            <img class="mobile" src={`${API_MATERIAL}/diets/media/${props.diet['lunch_file']}`} />


                            <img class="desktop" src={`${API_MATERIAL}/diets/media/${props.diet['lunch_file_mobile']}`} />

                        </div>
                    )}

                </div>

            </section>
        </div>
    )
}
