import React from 'react'
import Separador from '../img/linea-v-color.png'

import Queso from '../img/icono-cheese.png'
import Semillas from '../img/icono-cuchara.png'
import Cereales from '../img/icono-trigo.png'
import Guarnicion from '../img/icono-ensalada.png'
import Hervir from '../img/icono-olla.png'
import Limon from '../img/icono-limon.png'
import Enfriar from '../img/icono-frio.png'
import Jugos from '../img/icono-jugo.png'
import Bombilla from '../img/icono-tips.png';
/*import Slider from "react-slick";*/

export default function Tips() {


    const settings = {
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        appendDots: dots => (
            <div>
                {dots}
            </div>
        )
    };


    return (

        <div id="mqgtips" className="col-sm-12 pull-down">

            <div className="container">
                <div className="row">
                    <header className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <h2 id={"mqg-tips"} style={{ marginBottom: "0.5em" }}>#MQGtips</h2>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <img src={Bombilla} alt="Icono tips"/>
                            </div>
                        </div>
                        <img className={"mt-2"} src={Separador} alt="separador horizontal" />
                        <div className="mt-4 mb-5">
                            <h3 className="mb-4 font-weight-bold">Para bajar el índice glucémico</h3>
                        </div>
                    </header>
                    <div className="col-md-6">
                        <div className="d-flex justify-content-between flex-column">
                            <div className="mb-5 d-flex align-items-start"> <img className="img-fluid" style={{ marginRight: "1em" }} src={Queso} alt="Icono" />
                                <h5>Combina los hidratos de carbono con fibra (vegetales y frutas con cáscara) y proteínas (carnes, huevo y queso) en la misma comida.</h5>
                            </div>
                            <div className="mb-5 d-flex align-items-start">
                                <img className="img-fluid" style={{ marginRight: "1em" }} src={Semillas} alt="Icono" />
                                <h5>Incluye una cucharadita de semillas en tus comidas (las de chia y linaza aportan además omega 3).</h5>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between flex-column">
                            <div className="mb-5 d-flex align-items-start"> <img className="" style={{ marginRight: "1em" }} src={Hervir} alt="Icono" />
                                <h5>Cocina las pastas y el arroz al dente.</h5>
                            </div>
                            <div className="mb-5 d-flex align-items-start"> <img className="img-fluid" style={{ marginRight: "1em" }} src={Enfriar} alt="Icono" />
                                <h5>Refrigera (mínimo seis horas) cereales, papa y camote una vez cocidos.
                                    Dato: ¡aunque los vuelvas a calentar ya les bajaste el índice glicémico! </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <div className="mb-5 mb-md-3 d-flex align-items-start">
                                <img className="img-fluid" style={{ marginRight: "1em" }} src={Cereales} alt="Icono" />
                                <h5 >Elige los cereales en su versión integral (arroz integral, fideos integrales, pan integral, etc).
                                    <br /> Si los prefieres blancos, optá por el arroz que nunca se pasa (parboil) y los fideos de trigo candeal. </h5>
                            </div>
                            <div className="mb-5 mb-md-2 d-flex align-items-start"> <img className="img-fluid" style={{ marginRight: "1em" }} src={Guarnicion} alt="Icono" />
                                <h5>¡Innova! Reemplaza guarniciones típicas como el arroz blanco o puré de papas por:
                                    <br /> - Legumbres (arvejas, porotos, quinoa, etc).
                                    <br /> - Papa hervida con cáscara.</h5>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-5 d-flex align-items-start"> <img className="img-fluid" style={{ marginRight: "1em" }} src={Limon} alt="Icono" />
                                <h5>Combina los hidratos de carbono con alimentos ácidos como el vinagre y el jugo de limón.</h5>
                            </div>
                            <div className=" d-flex align-items-start"> <img className="img-fluid" style={{ marginRight: "1em" }} src={Jugos} alt="Icono" />
                                <h5>Evita alimentos ricos en hidratos de carbono procesados como: puré instantáneo, licuados y jugos de frutas; ya que se digieren y absorben rápido.</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}
