import React, { useState, useRef } from 'react';
import Swiper from 'react-id-swiper';

import '../css/swiper.min.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL_SLIDERS } from '../store/constants';
import Slider from "react-slick";
//sections_sliders/media/
//http://backend-qa.masqueglucosa.cl/material/sections_sliders/media/banner-chupetin.png


//IMG FONDOS
import DiabetesFondo from '../img/diabetes-banner.png'

import WspIcon from '../img/wsp-icon.png'

export default function HomeBanner() {

  const ref = useRef(null);

  const goNext = () => {
    if (ref.current !== null) {
      ref.current.slickNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null) {
      ref.current.slickPrev();
    }
  };
  const params = {
    dots: false,
    pagination: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    ref,
  };

  const banners = useSelector((state) => state.slidersReducer.sliders, shallowEqual);

  return (
    <main id="h-main">
      {' '}

      {/* <div className="header-fondo"></div> */}
      {banners && (
        <Slider {...params}>
          {/*  */}

          {banners.map((banner, index) => (
            <div>
              <div id={"banner"+index}
                style={{
                  backgroundImage: `url(${API_MATERIAL_SLIDERS}${window.screen.width < 500 && banner['file_mobile'] ? banner['file_mobile'] : banner['file_desktop']})`,
                }}
                // style={{
                //   backgroundImage: `url(${DiabetesFondo})`
                // }}
                className="carousel-inner container-banner">
                <div className="banner">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 text">
                        <h2>
                          {banner['title']}
                        </h2>
                        <p className="tag" >{banner['title2']}</p>
                        {banner['button_text'] && (
                            <a href={banner['url_canonical']}><button>{banner['button_text']}</button></a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )
          )}


        </Slider>
      )}
      <a class="carousel-control-prev" onClick={goPrev} role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a>
      <a class="carousel-control-next" onClick={goNext} role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
      {/* <div className="fixed-button">
        <button> <img className="mr-3" src={WspIcon} alt="" /> Enfermería</button>
      </div> */}

    </main>
  );
}
