import React from 'react'
import Instagram from '../img/instagram.png'
import Facebook from '../img/facebook.png'

export default function IframeHome() {
    return (
        <div id={"iframes"} className={"container"}>
            <div className={"row"}>
                <div className="col-md-6">
                    <h3><img src={Instagram} alt="Instagram"/> masqueglucosachile</h3>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <iframe width="320" height="500" src="https://instagram.com/p/CU-3N4zPSu_/embed"
                            frameBorder="0"></iframe>
                    </div>
                </div>
                <div className="col-md-6">
                    <h3><img src={Facebook} alt="Facebook"/> Más que glucosa Chile</h3>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <div className="fb-page" data-href="https://www.facebook.com/masqueglucosachile"
                             data-tabs="timeline" data-width="540" data-height="500" data-small-header="false"
                             data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true">
                            <blockquote cite="https://www.facebook.com/masqueglucosachile"
                                        className="fb-xfbml-parse-ignore"><a
                                href="https://www.facebook.com/masqueglucosachile" target="_top">Más que glucosa Chile</a></blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}