import React from 'react'

import IconoDeNuevo from '../img/test-de-nuevo.png'
import IconoDescargar from '../img/test-descargar.png'

//PDFS
import pdfAlto from '../pdfs/Resultado-ALTO.pdf'
import pdfBajo from '../pdfs/Resultado-BAJO.pdf'
import pdfLigeramenteElevado from '../pdfs/Resultado-LIGERAMENTE-ELEVADO.pdf'
import pdfModerado from '../pdfs/Resultado-MODERADO.pdf'
import pdfMuyAlto from '../pdfs/Resultado-MUY-ALTO.pdf'
import pdfGuia from '../pdfs/Guia-de-preguntas-MQG.pdf'




export default function Resultado(props) {
    return (
        <div className="resultado-container">
            <div className="container">
                <p className="su-resultado" >Su resultado: <span className="text-yellow" >{props.resultado['name']}</span></p>
                <h4>{props.resultado['title']}</h4>
                <p className="text" >{props.resultado['text1']}</p>
                <p className="text" >{props.resultado['text2']}</p>
                {props.resultado['text3'] && (
                    <p className="text" >{props.resultado['text3']}</p>
                )}
                <div className="buttons">
                    <div className="pdf">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a className="text-blue btn" target={"_blank"} href={pdfGuia}>GUÍA DE DISCUSIÓN CON TU MÉDICO</a>
                    </div>
                    <div>
                    <a className="text-yellow" href="/test-de-riesgo">
                        <img src={IconoDeNuevo} alt="" />
                        COMENZAR DE NUEVO
                    </a>
                    {props.resultado.name === 'BAJO' && (
                        <a className="text-blue" target="_blank" href={pdfBajo} download >
                            <img src={IconoDescargar} alt="" />
                        DESCARGARLO
                        </a>
                    )}
                    {props.resultado.name === 'LIGERAMENTE ELEVADO' && (
                        <a className="text-blue" target="_blank" href={pdfLigeramenteElevado} download >
                            <img src={IconoDescargar} alt="" />
                        DESCARGARLO
                        </a>
                    )}
                    {props.resultado.name === 'MODERADO' && (
                        <a className="text-blue" target="_blank" href={pdfModerado} download >
                            <img src={IconoDescargar} alt="" />
                        DESCARGARLO
                        </a>
                    )}
                    {props.resultado.name === 'ALTO' && (
                        <a className="text-blue" target="_blank" href={pdfAlto} download >
                            <img src={IconoDescargar} alt="" />
                        DESCARGARLO
                        </a>
                    )}
                    {props.resultado.name === 'MUY ALTO' && (
                        <a className="text-blue" target="_blank" href={pdfMuyAlto} download >
                            <img src={IconoDescargar} alt="" />
                        DESCARGARLO
                        </a>
                    )}
                    </div>
                </div>
                <p className="mt-sm-4">Estas no son recomendaciones médicas. Por favor consulte a su médico.</p>
            </div>
        </div>
    )
}