import {
    FETCH_EVENTS
  } from "../constants";
  
  const initialState = {
    events: [],
  };





export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS:
            return Object.assign({}, state, {
            events: action.events
            });
        default:
            return state;
    }
}