import React from 'react'
import { NavDropdown } from 'react-bootstrap'

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon
} from 'react-share';

export default function CompartirButton(props) {

    return (
        <NavDropdown className="button-compartir" id={props.id}>

            <NavDropdown.Item as="a">
                <WhatsappShareButton
                    children="Whatsapp"
                    url={props.url}
                    title={props.title}
                >
                    <WhatsappIcon className="icon" size={30} />
                         WhatsApp
                 </WhatsappShareButton>
            </NavDropdown.Item>

            <NavDropdown.Item as="a">
                <FacebookShareButton
                    children="Facebook"
                    url={props.url}
                    title={props.title}
                >
                    <FacebookIcon className="icon" size={30} />
                         Facebook
                 </FacebookShareButton>
            </NavDropdown.Item>

            <NavDropdown.Item as="a">
                <TwitterShareButton
                    children="Twitter"
                    url={props.url}
                    title={props.title}
                >
                    <TwitterIcon className="icon" size={30} />
                         Twitter
                 </TwitterShareButton>
            </NavDropdown.Item>

            <NavDropdown.Item as="a">
                <EmailShareButton
                    children="E-mail"
                    url={props.url}
                    title={props.title}
                    body={props.body}
                >
                    <EmailIcon className="icon" size={30} />
                         E-mail
                 </EmailShareButton>
            </NavDropdown.Item>

            <NavDropdown.Item as="a">
                <LinkedinShareButton
                    children="LinkedIn"
                    url={props.url}
                    title={props.title}
                >
                    <LinkedinIcon className="icon" size={30} />
                         LinkedIn
                 </LinkedinShareButton>
            </NavDropdown.Item>

        </NavDropdown>



    )
}
