import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import { useSelector, shallowEqual } from 'react-redux';
import Noticia from '../components/Noticia'
import NoticiasRandom from '../components/NoticiasRandom'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import TuEspacioDeDiabetesHome from '../components/TuEspacioDeDiabetesHome';


export default function TuEspacioDeDiabetesContainer() {
    const bodyClass = useSelector((state) => state.menu.bodyClass);



    return (



        <div id="espacio-diabetes-home" className={bodyClass}>
            {/* <Helmet>
                <title>{`${note.title}| Mas Que Glucosa`}</title>
                <meta name="description" content={note.title}></meta>
              
                <meta name="title" content={`${note.title}| Mas Que Glucosa`}></meta>
                <meta name="description" content="El diagnóstico se realiza a través de una historia clínica, examen físico y laboratorio."></meta>
           
            </Helmet> */}
            <Navbar />

            <SideNav />

            <TuEspacioDeDiabetesHome />


            <Footer />
        </div>
    )
}
