import React from 'react'

import Separador from '../img/hr-100-blanco.png'
import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser';
import CompartirNoticia from './CompartirNoticia';
import NoticiasRandom from './NoticiasRandom';
import {useLocation} from 'react-router-dom'

export default function Noticia() {

    const notes = useSelector((state) => state.notesReducer.notes, shallowEqual);
    console.log(notes);
    let location = useLocation();
    const note = notes.filter((note) => `/educacion/${note.id_note}`== location.pathname)[0]
    
    console.log(location.pathname);


    
  

    return (
        <div>
            <main className="faq-diabetes-main">
                
                {note && (   
                 
                        <div className="container">
                    
                      
                     
                            <div className="row">
                                <div className="col-md-4">
                                    <h2>{note.title}</h2> 

                                    <img src={Separador}/>
                            
                                    <div className="img" style={{backgroundImage: `url(${API_MATERIAL}/notes/media/${note['file_desktop']})`}}> </div>
                                    <CompartirNoticia/>
                                </div>
                                <div className="col-md-8 texto">
                                        
                                            {ReactHtmlParser(note.detail)}     
                                        
                                </div>  
                            </div>
                      
                     
                        </div>
                    
                   )} 
                    
                
            </main>

        
        </div>
    )
}
