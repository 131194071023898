import React from 'react'
import HombreOrganos from '../img/organos-men.png'
import MujerOrganos from '../img/organos-female.png'
import PersonasOrganos from '../img/personas-organos.png'

export default function TodoloqueNecesitasSaber() {
    return (
        <div>
            <section class="necesitas-saber" id="mas-que-glucosa">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-1">
                            <h6>TODO LO QUE NECESITAS SABER</h6>
                            <h3 className="text-celeste mb-4">La diabetes puede afectar órganos que no se ven.</h3>
                            <p>Tener el azúcar en sangre controlada no es lo único que importa, debemos también saber que hay órganos que pueden afectarse simplemente por tener diabetes. Estos órganos son <b>el corazón y los riñones</b>, y es importante que te acerques a tu médico especialista y le consultes si puedes hacer algo más para cuidarlos.</p>
                            <br />
                            <p>Las enfermedades cardiovasculares, renales y diabetes están estrechamente ligadas unas a otras y representan la causa de mayor mortalidad en el mundo. No podemos ignorar que están afectando alrededor de 20 millones de personas cada año.</p>
                            <br />
                            <p className="mt-3 text-right">Entérate que complicaciones se pueden dsencadenar con la diabetes <br/>
                            <span className={"text-blue"}>Explora el gráfico y entérate</span></p>
                        </div>
                        <div className="images-desktop col-md-6 justify-content-center align-items-center">
                            <iframe className="animacion-iframe" src="animación/animacion-cuerpohumano.html" frameBorder="0"></iframe>
                        </div>

                        <div className="col-md-5 mb-1"> </div>
                    </div>
                    <div className="images-mobile">
                        <img src={PersonasOrganos} alt="" />
                    </div>
                </div>
            </section>
        </div>
    )
}
