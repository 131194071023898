import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import { useSelector, shallowEqual } from 'react-redux'
import CalendarioActividades from '../components/CalendarioActividades'
import ActividadesSlider from '../components/ActividadesSlider'
import ActividadesRelacionadas from '../components/ActividadesRelacionadas'

export default function CalendariodeActividadesInterna() {

    const bodyClass = useSelector((state) => state.menu.bodyClass);

    return (
        <div id="calendario-actividades-interna" className={bodyClass}>
            <Navbar />
            <div className="container header-actividades">
                <div id="header-interna">
                    <p style={{ textTransform: 'uppercase' }} className="miga-pan" >CALENDARIO DE ACTIVIDADES</p>
                    <h3>CALENDARIO DE ACTIVIDADES</h3>
                </div>
            </div>
            <ActividadesSlider />
            <ActividadesRelacionadas />
            <SideNav />
            <Footer />
        </div>
    )
}
