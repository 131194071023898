import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SideNav from '../components/SideNav';
import {useSelector} from "react-redux";

export default function Default(props) {
    const bodyClass = useSelector((state) => state.menu.bodyClass);

    return (
        <div className={bodyClass}>
            <Navbar />
            <SideNav />
            <main>
                { props.children }
            </main>
            <Footer />
        </div>
    )
}