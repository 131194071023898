import {combineReducers} from 'redux';

import menuReducer from './menu';
import slidersReducer from './sliders';
import notesReducer from './notes';
import dietsReducer from './tips'
import doctorsReducer from './doctors'
import eventsReducer from './events'

export default combineReducers({
    menu: menuReducer,
    slidersReducer,
    notesReducer,
    dietsReducer,
    doctorsReducer,
    eventsReducer,
})
