import React from 'react'
import { Link } from 'react-router-dom'
import Actividades4x4 from '../components/Actividades4x4'

export default function ActividadesRelacionadasmobile() {
    return (
        <div id="actividades-relacionadas-mobile">
            <div className="container mt-5 mb-5  " style={{backgroundColor:"#f4f4f4"}}>
                <div className="d-flex justify-content-center mb-5 "> <div className="boton-calendario d-flex align-items-center justify-content-center">CALENDARIO DE ACTIVIDADES</div></div>
            
          
            <Actividades4x4/>
            </div>
        </div>
    )
}
