import React from 'react'
import Slider from 'react-slick'

import {
    Link,
} from "react-router-dom";

// API RUTA IMAGENES
import { API_MATERIAL_NOTES_FILES } from '../store/constants'

// PARSEA EL HTML QUE VIENE DEL BACK
import ReactHtmlParser from 'react-html-parser'



export default function CarouselResponsive(props) {

    const settings = {

        className: "center",
        centerMode: props.home ? false : true,
        infinite: true,
        centerPadding: "60px",

        arrows: false,
        slidesToShow: 3,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 7,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    };


    return (
        <div>
            {props.home ? (
                <Slider
                    {...settings}
                    className="carousel-responsive in-home"
                >
                    {props.arrayNotes.map(note => (
                        <div className="nota" >
                            <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                            </Link>
                            <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                <h5 className="home-mobile" >{note['title']}</h5>
                            </Link>

                        </div>
                    ))}
                </Slider>
            ) : (
                    <Slider
                        {...settings}
                        className="carousel-responsive"
                    >
                        {props.arrayNotes.map(note => (
                            <div className="nota" >
                                <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                    <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                                </Link>
                                <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                    <h5>{note['title']}</h5>
                                </Link>
                                <p>{ReactHtmlParser(note['detail'].slice(0, 150))}</p>
                            </div>
                        ))}
                    </Slider>
                )}
        </div>
    )
}