import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { API_MATERIAL } from '../store/constants';
import { Link } from 'react-router-dom'
import yoga from '../img/217.png'
import yoga2 from '../img/yoga2.png'
import yoga3 from '../img/yoga3.png'
import yoga4 from '../img/yoga4.png'
import Slider from 'react-slick';
export default function Actividades4x4(props) {

    const events = useSelector((state) => state.eventsReducer.events, shallowEqual);

    const settings = {

        infinite: true,
        arrows: false,
        slidesToShow: 2,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    };
    return (
        <div>
            <div className="row align-items-center calendario-actividades">
                <div className="container">


                    <div className="row">
                        {events.map((event) => (
                            <div className={`actividad col-md-${props.col ? props.col : '5'} mb-5`}>
                                <Link
                                    to={`/calendario-de-actividades/${event.id_event}`}>
                                    <img src={`${API_MATERIAL}/events/media/${event['photo']}`} className="image float-left" style={{ marginRight: "1em" }} />
                                </Link>
                                <p style={{ color: "#0085c9" }}>{event.author}</p>
                                <Link
                                    to={`/calendario-de-actividades/${event.id_event}`}>
                                    <h5 className="eventos-negro-celeste">{event.title}</h5>
                                </Link>
                                <div className="detail">
                                    <p style={{ color: "#0085c9" }}>{event.schedule+ " " +event.hours} </p>
                                    
                                </div>
                            </div>
                        ))}
                    </div>


                </div>


            </div>
        </div>
    )
}
