import React from 'react'
import {useSelector} from 'react-redux'


import Escaleras from '../img/escaleras.jpg'
import Cordones from '../img/cordones.jpg'

import IconTarea from '../img/icono-tareas.png'
import IconCaminar from'../img/icono-caminar.png'
import IconEscaleras from'../img/icono-escaleras.png'
import IconPausas from'../img/icono-pausas.png'
import Condicion from'../img/icono-condicion.png'
import Rutina from'../img/icono-rutina.png'
import Medicion from'../img/icono-medicion.png'
import Alimentacion from'../img/icono-alimentacion.png'
import Separador from '../img/hr-100-blanco.png'
import Frecuencia from '../img/frecuencia.jpg'
import Navbar from '../components/Navbar'
import SideNav from '../components/SideNav'
import Tips from '../components/Tips'
import Footer from '../components/Footer'

import Milqui from '../img/cover-1500kcal.jpg'

import Milocho from '../img/cover-1800kcal.jpg'
import {Helmet} from 'react-helmet'

export default function EjercicioContainer() {


    const bodyClass = useSelector((state)=> state.menu.bodyClass);
    console.log(bodyClass);

    return (
        <div  className={bodyClass} id="ejercicio-fisico">
            <Helmet>
            <title>Ejercicio Físico | Mas Que Glucosa</title>
            <meta name="description" content="La actividad física es uno de los mejores aliados de la persona con diabetes. No solamente le ayuda a mantener un peso saludable sino que también mejora sus niveles de glucosa en la sangre."></meta>
            </Helmet>
            <Navbar/>
            <SideNav/>
           
                <section className="header-interna">
                <div className="container">
                    <h6>CONSEJOS ÙTILES</h6>
                    <h1>Ejercicio físico</h1> </div>
                </section>


        <main>
            <div className="row m-0 bg-celeste-dark p-0">
                <div className="col-md-6 m-0 p-0 imagen"><img src={Cordones}/></div>
                <div className="col-md-6 texto">
                    <h3>Rutinas de ejercicio</h3>
                    <h4>La actividad física es uno de los mejores aliados de la persona con diabetes. No solamente le ayuda a mantener un peso saludable sino que también mejora sus niveles de glucosa en la sangre.</h4> </div>
            </div>
         </main>

        <section id="para-todos">
            <div className="container">
                <h3>Para todos</h3>
                <div className="swiper-container sw-paratodos">
                    <div className="swiper-wrapper  overide-swiper-wrapper">
                        <div className="swiper-slide"> <img src={IconTarea}/>
                            <h4>Realizá más tareas
                                domésticas</h4>
                            <p>Encargate de la jardinería o realizá rutinas, programas de limpieza.</p>
                        </div>
                        <div className="swiper-slide"> <img src={IconCaminar}/>
                            <h4>caminá mas</h4>
                            <p>Si no podés caminar hasta el trabajo, dejá tu automóvil lejos de la oficina para aumentar la distancia caminada.</p>
                        </div>
                        <div className="swiper-slide"> <img src={IconEscaleras}/>
                            <h4>usá las escaleras</h4>
                            <p>Subí por las escaleras en vez de utilizar el ascensor.</p>
                        </div>
                        <div className="swiper-slide"> <img src={IconPausas}/>
                            <h4>hacé pausas activas</h4>
                            <p>Aprovechalas para dar una pequeña caminata, estirar o realizar cualquier otra actividad en la que utilices energía.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

    <section id="ejercicios" className="bg-celeste-dark">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3 className="text-white">Ejercicios y deportes</h3> </div>
                <div className="col-md-6">
                    <h4 className="mb-4">Consultá con el médico para empezar gradualmente a ejecutar rutinas de ejercicio o deporte.<br/><br/>

                        Para todo deporte que realices es importante seguir las próximas 3 etapas:</h4> <img src={Separador} className="mb-5"/> </div>
                <div className="offset-md-1 col-md-5">
                    <ul className="etapas">
                        <li className="etapa">
                            <p className="numero">1.</p>
                            <p>Calentamiento de 5 a 10 minutos con ejercicios suaves de estiramiento.</p>
                        </li>
                        <li className="etapa">
                            <p className="numero">2.</p>
                            <p>Rutina de ejercicio de 30 a 50 minutos.</p>
                        </li>
                        <li className="etapa">
                            <p className="numero">3.</p>
                            <p>Enfriamiento y estiramiento.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section id="frecuencia" className="bg-gris">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3>¿Cómo se puede lograr una actividad física adecuada?</h3> </div>
                <div className="col-md-6"><img src={Frecuencia}/></div>
                <div className="col-md-6 d-flex flex-column justify-content-center">
                    <p>Para saber si se está logrando una actividad física adecuada, <b>medí tu frecuencia cardíaca manualmente</b> (ubicá los dedos índice y corazón en alguna parte del cuerpo donde una arteria pase muy cerca a la piel, como en el cuello o en la muñeca) <b>o con algún dispositivo electrónico.</b> La frecuencia cardíaca durante el ejercicio debe ser un</p>
                    <div className="highlight text-celeste">
                        <p className="numero">60/80%</p>
                        <p className="texto">MÁS
                            <br/> ALTA</p>
                    </div>
                    <p>que la frecuencia cardíaca en reposo. Por ejemplo, si tu frecuencia cardíaca en reposo es de <b>80 latidos por minuto</b>, para que el ejercicio sea adecuado, esta <b>debe estar por encima de 128 y por debajo de 144 latidos por minuto</b> durante la actividad física.</p>
                </div>
            </div>
        </div>
    </section>
    <section id="aerobico" className="gradient" style={{backgroundImage:`url(${Escaleras})`}}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3 className="text-white">Ejercicio aérobico</h3> </div>
                <div className="offset-md-2 col-md-8 text-center text-white d-flex flex-column align-items-center">
                    <p>Para que el ejercicio aeróbico impacte en la calidad de vida, este se debe realizar al menos</p>
                    <div className="highlight">
                        <p className="numero">3</p>
                        <p className="texto text-left">veces por
                            <br/> semana</p>
                    </div>
                    <p>Sin embargo, lo ideal es realizar sesiones de actividad física intensa cinco o seis días a la semana. </p>
                </div>
            </div>
        </div>
    </section>
    <section id="consulta">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <h3>Consultá con tu médico</h3>
                    <h4 className="mb-5">Antes de realizar cualquier actividad es fundamental consultar con tu médico:</h4>
                    <div className=" sw-consultar">
                        <div  className="swiper-wrapper  overide-swiper-wrapper">
                            <div className="swiper-slide "> <img src={Condicion}/>
                                <p>Cuál es el ejercicio más apropiado de acuerdo a tu condición médica.</p>
                            </div>
                            <div className="swiper-slide"> <img src={Rutina}/>
                                <p>Cómo debe ser tu rutina inicial y cómo debes ir incrementando gradualmente la duración y la intensidad.</p>
                            </div>
                            <div className="swiper-slide"> <img src={Medicion}/>
                                <p>Cómo debés modificar tu rutina dependiendo de tus mediciones de glucosa en sangre.</p>
                            </div>
                            <div className="swiper-slide"> <img src={Alimentacion}/>
                                <p>Cómo debes alimentarte antes y después.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="offset-md-1 col-md-3">
                    <div className="sidebar pt-2">
                        <h6>OTROS CONSEJOS</h6>
                        <div className="seccion">
                            <a href="">
                                <a href="1500kcal.html">
                                    <div className="img gradient" style={{backgroundImage:`url(${Milqui})`}}> </div>
                                    <h5 className="text-celeste">Dieta - 1500 KCAL</h5> </a>
                            </a>
                            <p>Los cambios sanos en tu vida pueden ralentizar o detener el desarrollo de la diabetes.</p>
                        </div>
                        <div className="seccion">
                            <a href="">
                                <a href="1800kcal.html">
                                    <div className="img gradient" style={{backgroundImage:`url(${Milocho})`}}> </div>
                                    <h5 className="text-celeste">Dieta - 1800 KCAL</h5> </a>
                            </a>
                            <p>Esta dieta de 1800 KCAL, esta pensada para vos, ¡descubrila!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
 
        <Tips/>
        <Footer/>
        </div>
    )
}
