import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Tips from '../components/Tips'
import ConsejosUtiles from '../components/ConsejosUtiles'
import Video from '../components/Video'
//import Educacion from '../components/Educacion'
import EducacionSlider from '../components/EducacionSlider'
import Numeros from '../components/Numeros'
import TodoloqueNecesitasSaber from '../components/TodoloqueNecesitasSaber'
import '../css/swiper.min.css';


import SideNav from '../components/SideNav'

import { useSelector } from 'react-redux'
import HomeBanner from '../components/HomeBanner'

import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'
import IconosHome from '../components/IconosHome'
import TuEspacioDiabetesSectionInHome from '../components/TuEspacioDiabetesSeccionInHome'
import NumerosRiesgo from '../components/NumerosRiesgo'
import CalendarioActividades from '../components/CalendarioActividades'
import IframeHome from "../components/IframeHome";


export default function HomeContainer() {

    const bodyClass = useSelector((state) => state.menu.bodyClass);
    console.log(bodyClass);

    return (
        <div id="home">
            <Helmet>
                <title>Mas Que Glucosa</title>
                <meta name="description" content=" "></meta>
                <SEO />
            </Helmet>
            <div className={bodyClass}>


                <Navbar />
                <SideNav />

                <HomeBanner />
                <IconosHome />

                <TodoloqueNecesitasSaber />
                <TuEspacioDiabetesSectionInHome />
                <NumerosRiesgo />
                {/* <Numeros /> */}



                {/* <EducacionSlider /> */}
                {/* <Video /> */}
                {/* <EducacionSlider />
                <Video /> */}
                {/*<CalendarioActividades />*/}
                <ConsejosUtiles />
                <Tips />
                <IframeHome />

                <Footer />

            </div>
        </div>
    )
}
