import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HomeContainer from './containers/HomeContainer';
import EducacionContainer from './containers/EducacionContainer';
import TestdeRiesgoContainer from './containers/TestdeRiesgoContainer';
import './css/estilos-mqg.css';
import { fetchData } from './store/actions/fetchandstore';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import TipsContainer from './containers/TipsContainer';
import DoctoresContainer from './containers/DoctoresContainer';
import EjercicioContainer from './containers/EjercicioContainer';
import ScrollTop from './components/ScrollTop';
import ReactGA from 'react-ga';
import TuEspacioDeDiabetesContainer from './containers/TuEspacioDeDiabetesContainer';
import TuEspacioDeDiabetesNotaContainer from './containers/TuEspacioDeDiabetesNotaContainer';
import CalendariodeActividadesInterna from './containers/CalendariodeActividadesInterna';
import ActividadesContainer from './containers/ActividadContainer';
import TestDeRiesgoResult from "./containers/TestDeRiesgoResult";
import Container404 from "./containers/Container404";
import TestError from "./components/test-error";
import NotificacionLegalContainer from "./containers/NotificacionLegalContainer";
import DiaDiabetes from "./components/DiaDiabetes";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.initialize('UA-172516515-1');
    dispatch(fetchData());
  }, []);

  const redirectToExternalLink = () => {
    window.location.href = 'https://qr.short.az/ae-chile';
  };

  return (
    <div className="App">
      <Router>
        <ScrollTop>
          <Switch>
            <Route path="/" exact component={HomeContainer} />
            <Route path="/educacion/" exact component={HomeContainer} />
            <Route path="/educacion/:id" component={EducacionContainer} />
            <Route path="/contacto" exact render={redirectToExternalLink} />
            <Route path="/consejos/:slug" component={TipsContainer} />
            <Route path="/tips/:id" component={TipsContainer} />
            <Route path="/doctor/:id" component={DoctoresContainer} />
            <Route
              path="/espacio-diabetes"
              exact
              component={TuEspacioDeDiabetesContainer}
            />
            <Route
              path="/espacio-diabetes/:id"
              component={TuEspacioDeDiabetesNotaContainer}
            />
            <Route
              path="/ejercicio-fisico"
              exact
              component={EjercicioContainer}
            />
            <Route
              path="/calendario-de-actividades"
              exact
              component={CalendariodeActividadesInterna}
            />
            <Route
              path="/calendario-de-actividades/:id"
              component={ActividadesContainer}
            />
            <Route
              path="/test-de-riesgo"
              exact
              component={TestdeRiesgoContainer}
            />
            <Route
              path="/test-de-riesgo/:result"
              exact
              component={TestDeRiesgoResult}
            />
            <Route path="/test-error" exact component={TestError} />
            <Route
              path="/notificacion-legal"
              exact
              component={NotificacionLegalContainer}
            />
            <Route
              path="/dia-mundial-diabetes"
              exact
              component={DiaDiabetes}
            />
            <Route path="/404" component={Container404} />
            <Redirect to="/404" />
          </Switch>
        </ScrollTop>
      </Router>
    </div>
  );
}

export default App;
