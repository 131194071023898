import React from 'react'

import CompartirButton from './CompartirButton';

export default function CompartirResultado(props) {

    return (
        <section className="compartir-resultado-container">
            <div className="container">
                <h4>AYUDÁ A MÁS PERSONAS A COMPRENDER LA DIABETES Y COMBATIRLA. COMPARTÍ EL TEST.</h4>
                <div className="container-button-compartir" >
                    <CompartirButton
                        url={window.location.href}
                        title={''}
                        body={''}
                    />
                </div>
            </div>
            <div className="container-images"></div>
        </section>
    )
}