import React from 'react'
import NumerosSlider from './NumerosSlider'
import Video from './Video'

export default function NumerosRiesgo() {
    return (
        <section className="numeros-riesgo-container p-0">
            <NumerosSlider />
            <Video />
        </section>
    )
}