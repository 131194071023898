import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SideNav from '../components/SideNav'
import { useSelector, shallowEqual } from 'react-redux'
import BannerTest from '../components/BannerTest'
import TestPreguntas from '../components/TestPreguntas'

export default function TestdeRiesgoContainer() {
    const bodyClass = useSelector((state) => state.menu.bodyClass);
    return (
        <div id="calendario-actividades-interna" className={bodyClass}>

            <Navbar />
            <SideNav />

            <BannerTest />
            <TestPreguntas />
            <div className="footer-bottom bg-gris py-5 my-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="disclaimer" id="referencia">
                                <p>
                                    1. Lindström J, Tuomilehto J. The Diabetes Risk Score: A practical tool to predict type 2 diabetes risk. Diabetes Care 2003; 26: 725-731.
                                </p>
                                <p>2. Test2Prevent, online risk assessment for type 2 diabetes, International Diabetes Federation, 2019, <a href="https://www.idf.org/type-2-diabetes-risk-assessment/es/" target={"_blank"}>https://www.idf.org/type-2-diabetes-risk-assessment/es/</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
