import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { API_MATERIAL } from '../store/constants';
import CalendarioActividades from '../components/CalendarioActividades'
import ActividadesSlider from '../components/ActividadesSlider'
import Foto from '../img/controlPeso.png'
import Calendario from '../img/Calendario.png'
import Doctor from '../img/Doctor.png'
import Calendario2 from '../img/iconCalendar.png'
import Compartir from '../img/iconCompartir.png'
import ActividadesRelacionadas from '../components/ActividadesRelacionadas'
import LAPDI from '../img/logo2.png'
import AgregaralCalendario from '../components/AgregaralCalendario'
import CompartirButton from '../components/CompartirButton'

export default function ActividadesContainer() {

    const bodyClass = useSelector((state) => state.menu.bodyClass);

    let { id } = useParams()

    const events = useSelector((state) => state.eventsReducer.events, shallowEqual);
    const Event = events.filter(event => event['id_event'] == id)[0];

    return (
        <div className={bodyClass}>
            <Navbar />

            <div className="container">
                <div id="header-interna">

                    <p style={{ textTransform: 'uppercase' }} className="miga-pan" >
                        <Link
                            to="/calendario-de-actividades"
                            style={{ textDecoration: 'none', color: '#9F9F9F' }}
                        >
                            CALENDARIO DE ACTIVIDADES
                    </Link>
                        {` / ${Event && Event['title']}`}</p>
                    <h3>CALENDARIO DE ACTIVIDADES</h3>
                </div>
            </div>

            <div id="actividad-container" className="container" style={{ paddingTop: "4em" }}>

                <div className="d-flex col-sm-12  " style={{ backgroundColor: "#F4F4F4" }}>
                    {Event && (
                        <div className="actividad-sliderr ">
                            <div className="image-container" >
                                <img src={`${API_MATERIAL}/events/media/${Event['photo']}`} />
                            </div>
                            <p style={{ color: "#0085c9", fontSize: "14pt", marginTop: "1em" }}>ORGANIZA :  {Event['organizer_name']}</p>
                            <h4 style={{ fontSize: "24pt", marginTop: "1em" }}>{Event['title']}</h4>
                            <h5 style={{ color: "#0085c9", fontSize: "15pt", marginTop: "1em" }}>{Event['subtitle']}</h5>
                            <div className="d-flex justify-content-end logo-organizador">
                                <img src={`${API_MATERIAL}/events/media/${Event['organizer_logo']}`} />
                            </div>
                        </div>
                    )}

                </div>
                <div className="col-sm-12 esconder-desktop  " style={{ backgroundColor: "#F4F4F4" }}>
                    {Event && (
                        <div className="d-flex">
                            <div className="actividad-slider" style={{ backgroundColor: "#F4F4F4" }}>

                                <p style={{ color: "#0085c9", fontWeight: 'bold' }}>ORGANIZA :  {Event['organizer_name']}</p>
                                <h4>{Event['title']}</h4>
                                <h5 className="subtitle" style={{ color: "#0085c9" }}>{Event['subtitle']}</h5>
                                <div className="d-flex logo-organizador" style={{marginLeft:'0rem'}}>
                                    <img src={`${API_MATERIAL}/events/media/${Event['organizer_logo']}`} />
                                </div>
                            </div>
                            <div className="image-container actividad-slider-img" style={{ backgroundColor: "#F4F4F4" }}>
                                <img src={`${API_MATERIAL}/events/media/${Event['photo']}`} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="container">
                <div className="d-flex col-sm-12 " style={{ backgroundColor: "#F4F4F4" }}>
                    {Event && (
                        <div className="actividad-slider-data esconder-desktop" style={{ backgroundColor: "#F4F4F4" }}>
                            <div className="row">

                                <div className="bloque col">
                                    <p style={{ color: "#0085c9" }}>¿CUÁNDO?</p>
                                    <h5>{Event['schedule']}</h5>
                                    <img className="d-inline" src={Calendario} />
                                    <h5 className="d-inline">{Event['hours']}</h5>
                                </div>

                                <div className="bloque col  ">
                                    <p style={{ color: "#0085c9" }}>DESCRIPCIÓN</p>
                                    {
                                        Event['zoom_id'] && !Event['zoom_pass'] ?
                                            <h5 style={{paddingTop:'1rem'}}>
                                                <a href={`${Event['zoom_id']}`} target="_blank">ASISTIR AL EVENTO</a>
                                            </h5>
                                        :
                                            <>
                                                <h5 style={{overflowWrap:'break-word'}}>ZOOM ID:  {Event['zoom_id']}</h5>
                                                <h5>Contraseña: {Event['zoom_pass']}</h5>
                                            </>
                                    }
                                    
                                </div>

                            </div>

                            <div className="row ">

                                <div className="bloque col">
                                    <p style={{ color: "#0085c9" }}>¿DÓNDE?</p>
                                    <h5>ZOOM</h5>
                                </div>
                                {
                                    Event['doctor']['name'] !== "NoDoctor" ?
                                
                                        <div className="bloque doctor-info col">
                                            <img src={`${API_MATERIAL}/doctors/media/${Event['doctor']['file']}`} />
                                            <h5 className="d-inline"> {Event['doctor']['name']}</h5>
                                        </div>
                                    : null
                                }
                            </div>
                            <div style={{ paddingTop: "1em" }} className="row  ">
                                <div className="col-md-6 fake-w"></div>
                                <div className="col-md-6 d-flex compartir mb-5">

                                    <div className="container-button-compartir" >
                                        <CompartirButton
                                            url={window.location.href}
                                            title={Event['title']}
                                            body={'Para ver el evento haga click en la siguiente URL'}
                                        />
                                        <p className="d-inline pl-2 pr-2">COMPARTIR</p>
                                    </div>

                                    <div className="d-flex col">
                                        <img className="d-inline pl-2" style={{ height: 'fit-content' }} src={Calendario2} />
                                        <div className="boton-blanco calendar-button">
                                            <AgregaralCalendario
                                                title={Event['title']}
                                                detail={Event["subtitle"]}
                                                place={"ZOOM"}
                                                start={Event['calendar_date_start']}
                                                end={Event['calendar_date_end']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}


                    {Event && (
                        <div className="actividad-slider-data-mobile ">


                            <div className="bloque">
                                <p style={{ color: "#0085c9" }}>¿CUÁNDO?</p>
                                <h5>{Event['schedule']}</h5>
                                <img className="d-inline" src={Calendario} />
                                <h5 className="d-inline">{Event['hours']}</h5>
                            </div>

                            <div className="bloque ">
                                <p style={{ color: "#0085c9" }}>DESCRIPCIÓN</p>
                                {
                                        Event['zoom_id'] && !Event['zoom_pass'] ?
                                            <h5 style={{paddingTop:'1rem'}}>
                                                <a href={`${Event['zoom_id']}`} target="_blank">ASISTIR AL EVENTO</a>
                                            </h5>
                                        :
                                            <>
                                                <h5 style={{overflowWrap:'break-word', paddingRight:'1rem'}}>ZOOM ID:  {Event['zoom_id']}</h5>
                                                <h5>Contraseña: {Event['zoom_pass']}</h5>
                                            </>
                                }
                            </div>

                            <div className="bloque ">
                                <p style={{ color: "#0085c9" }}>¿DÓNDE?</p>
                                <h5>ZOOM</h5>
                            </div>

                            {
                                Event['doctor']['name'] !== "NoDoctor" ?
                            
                                    <div className="bloque">
                                        <img src={`${API_MATERIAL}/doctors/media/${Event['doctor']['file']}`} />
                                        <h5 className="d-inline"> {Event['doctor']['name']}</h5>
                                    </div>
                                : null
                            }

                            <div style={{ paddingTop: "4em" }} className="row shares-buttons ">

                                <div className="col-md-6 d-inline compartir  mb-5 bloque">

                                    <div className="container-button-compartir" >
                                        <CompartirButton
                                            url={window.location.href}
                                            title={Event['title']}
                                            body={'Para ver el evento haga click en la siguiente URL'}
                                        />
                                        <p className="d-inline pl-2 pr-2">COMPARTIR</p>
                                    </div>

                                    <div className="d-flex col calendar">
                                        <img className="d-inline " style={{ height: 'fit-content' }} src={Calendario2} />
                                        <div className="boton-blanco calendar-button">
                                            <AgregaralCalendario
                                                title={Event['title']}
                                                detail={Event["subtitle"]}
                                                place={"ZOOM"}
                                                start={Event['calendar_date_start']}
                                                end={Event['calendar_date_end']}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </div>
            <ActividadesRelacionadas />
            <SideNav />
            <Footer />
        </div>
    )
}