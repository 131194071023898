import React from 'react'
import qr from '../img/qr.png'

export default function FooterLegal() {
    return (
        <div>
            <div class="footer-bottom bg-gris pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 disclaimer">

                            <p style={{ fontSize: "0.8em" }}> © AstraZeneca 2023. Todos los derechos reservados. Código de Aprobación: Material: CL-4224     fecha aprobación 01-2023<br />

                    La información contenida en este sitio web no representa recomendaciones médicas. Ante cualquier duda se debe consultar a un médico.<br />

                    Las notas contenidas en este sitio web representan exclusivamente la opinión de sus autores, y no la de AstraZeneca ni de las Asociaciones y Sociedades médicas involucradas en masqueglucosa.El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326.<br />

                    La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.
                    </p>
                        </div>
                        {/* <div class="col-md-3 d-flex qr"><a href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/30500772324--RL-2019-52463572-APN-DNPDP#AAIP" class="" target="_blank"><img src={qr}></img></a></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
