import { FETCH_SLIDERS, FETCH_NOTES, FETCH_TIPS, API, FETCH_DIETS,  FETCH_HELPFUL_AUTHORS, FETCH_DOCTORS, FETCH_EVENTS } from "../constants";


const setNotes = notes => ({
  type: FETCH_NOTES,
  notes
});

const setSliders = (sliders) => ({
  type: FETCH_SLIDERS,
  sliders
})


const setDiets = diets => ({
  type: FETCH_DIETS,
  diets
});
const setDoctors = doctors => ({
  type: FETCH_DOCTORS,
  doctors
});
const setEvents = events => ({
  type: FETCH_EVENTS,
  events
});

export const fetchData = () => dispatch => {
  fetch(API)
    .then(res => res.json())
    .then(data => {
      dispatch(setSliders(data["sliders"]))
      dispatch(setNotes(data["notes"]));
      dispatch(setDiets(data["diets"]));
      dispatch(setDoctors(data["doctors"]));
      dispatch(setEvents(data["events"]));
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });
};