import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

// PARSEA EL HTML QUE VIENE DEL BACK
import ReactHtmlParser from 'react-html-parser'

import hr_blanco from '../img/hr-100-blanco.png'

import CarouselResponsive from './CarouselResponsive'


import MultiCarousel from './MultiCarousel'
import CompartirButton from './CompartirButton';


// API RUTA IMAGENES
import { API_MATERIAL_NOTES_FILES } from '../store/constants'

export default function TuEspacioDeDiabetesHome() {

    let { id } = useParams()

    // NOTAS
    const notes = useSelector(state => state.notesReducer.notes, shallowEqual)

    const Note = notes.filter(note => note['id_note'] == id)[0]

    const notesDestacadas = useSelector(state => state.notesReducer.notes.filter(note => note.highlighted == '1'), shallowEqual)
    const noDestacadas = useSelector(state => state.notesReducer.notes.filter(note => note.highlighted != '1'), shallowEqual)
    console.log(noDestacadas)





    return (
        <div className="espacio-diabetes-home" >

            {notes.length > 0 && (
                <article className="nota-principal">
                    <div className="container">
                        <div className="title">
                            <p style={{ textTransform: 'uppercase' }} className="miga-pan" >TU ESPACIO DE DIABETES</p>
                            <h3>TU ESPACIO DE DIABETES</h3>
                        </div>
                        <div className="content row">
                            <div className="col-12 mb-4 mb-md-0 col-md-6">
                                <img src={`${API_MATERIAL_NOTES_FILES}${notes[0]["file_desktop"]}`} alt="" />
                            </div>
                            <div className="col-12 col-md-6">
                                <Link to={`/espacio-diabetes/${notes[0]['id_note']}`}>
                                    <h4>
                                        {notes[0]["title"]}
                                    </h4>
                                </Link>
                                <p>
                                    {ReactHtmlParser(notes[0]["detail"].slice(0, 600))} <span><Link to={`/espacio-diabetes/${notes[0]['id_note']}`} >Leer más</Link></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
            )}
            {/* {notes && (
                <article className="otras-notas" >
                    <div className="container">
                        <div >
                            <MultiCarousel
                                notes={noDestacadas}
                            />
                        </div>
                    </div>
                    <CarouselResponsive
                        arrayNotes={noDestacadas}
                    />
                </article>
            )} */}
            {notesDestacadas && (
                <article className="noticias-destacadas" >
                    <div className="container" style={{width:'100%',maxWidth:'100%',padding:0,paddingLeft:'5rem',paddingRight:'5rem', margin:0}}>
                        <div className="title">
                            <h3>NOTICIAS DESTACADAS</h3>
                        </div>
                        <div className="notes" style={{justifyContent:'space-around'}}>
                            {notesDestacadas.map((note) => (
                                <div className="nota" style={{width:'45%'}}>
                                    <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                        <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                                    </Link>

                                    <Link to={`/espacio-diabetes/${note['id_note']}`} >
                                        <h4>{note['title']}</h4>
                                    </Link>

                                    <p style={{textAlign:'justify'}}>{ReactHtmlParser(note['subtitle'])}</p>
                                </div>
                            ))}
                            {/* <div className="nota">
                            <Link to={`/espacio-diabetes/${notes[3]['id_note']}`} >
                                <img src={`${API_MATERIAL_NOTES_FILES}${notes[3]["file_desktop"]}`} alt="" />
                            </Link>

                            <Link to={`/espacio-diabetes/${notes[3]['id_note']}`} >
                                <h4>{notes[3]['title']}</h4>
                            </Link>

                            <p>{ReactHtmlParser(notes[3]['detail'].slice(0, 120))}</p>
                        </div>
                        <div className="nota">
                            <Link to={`/espacio-diabetes/${notes[2]['id_note']}`} >
                                <img src={`${API_MATERIAL_NOTES_FILES}${notes[2]["file_desktop"]}`} alt="" />
                            </Link>

                            <Link to={`/espacio-diabetes/${notes[2]['id_note']}`} >
                                <h4>{notes[2]['title']}</h4>
                            </Link>

                            <p>{ReactHtmlParser(notes[2]['detail'].slice(0, 120))}</p>
                        </div> */}
                        </div>
                    </div>
                    <CarouselResponsive
                        arrayNotes={notesDestacadas}
                    />
                </article>
            )}
            {notes && (
                <article className="otras-notas" >
                    <div className="container">
                        <div >
                            <MultiCarousel
                                notes={noDestacadas}
                            />
                        </div>
                    </div>
                    <CarouselResponsive
                        arrayNotes={noDestacadas}
                    />
                </article>
            )}
            {/* <article className="nota-principal">
                <div className="container title">
                <p className="miga-pan" >

                                TU ESPACIO DIABETES

                            {" "}/ {Note && Note['title']}</p>
                    <h3>TU ESPACIO DE DIABETES</h3>
                </div>
                {Note && (
                    <div id="header-espacio-diabetes-home" className="header">
                        <div className="image">
                            <img src={`${API_MATERIAL_NOTES_FILES}${Note['file_desktop']}`} alt="" />
                        </div>
                        <div className="content fondo-gradient-blue">
                            <div>
                                <h2>{Note['title']}</h2>
                                <img src={hr_blanco} alt="" />
                            <div className="container-button-compartir-espacio-diabetes" >
                            <CompartirButton
                                url={window.location.href}
                                title={Note['title']}
                                body={'Para ver la nota haga click en la siguiente URL'}
                            />
                        </div>
                            </div>
                        </div>
                    </div>
                )}

                {Note && (
                    <div className="container-description">
                        <div className="container">
                            {ReactHtmlParser(Note['detail'])}
                        </div>
                    </div>
                )}

            </article> */}
            {/* <article className="otras-notas" >
                <div className="container title">
                    <h3>TAMBIÉN TE PUEDE INTERESAR</h3>
                </div>

                <div className="container multi-desktop">
                    <MultiCarousel
                        notes={notes}
                    />
                </div>
                <CarouselResponsive
                    arrayNotes={notes}
                />
            </article> */}
            {/* <article className="noticias-destacadas" >
                <div className="container">
                    <div className="title">
                        <h3>NOTICIAS DESTACADAS</h3>
                    </div>
                    <div className="notes">
                        <div className="nota">
                            <Link to={`/espacio-diabetes/${notes[3]['id']}`} >
                                <img src={notes[3]['file']} alt="" />
                            </Link>

                            <Link to={`/espacio-diabetes/${notes[3]['id']}`} >
                                <h4>{notes[3]['title']}</h4>
                            </Link>

                            <p>{notes[3]['detail'].slice(0, 120)}</p>
                        </div>
                        <div className="nota">
                            <Link to={`/espacio-diabetes/${notes[2]['id']}`} >
                                <img src={notes[2]['file']} alt="" />
                            </Link>

                            <Link to={`/espacio-diabetes/${notes[2]['id']}`} >
                                <h4>{notes[2]['title']}</h4>
                            </Link>

                            <p>{notes[2]['detail'].slice(0, 120)}</p>
                        </div>
                    </div>
                </div>
                <CarouselResponsive
                    arrayNotes={notes.slice(1, 4)}
                />
            </article>
            <article className="otras-notas" >
                <div className="container">
                    <div className="content">
                        {notes.slice(1, 4).map(note => (
                            <div className="nota" >
                                <Link to={`/espacio-diabetes/${note['id']}`} >
                                    <img src={note['file']} alt="" />
                                </Link>
                                <Link to={`/espacio-diabetes/${note['id']}`} >
                                    <h5>{note['title']}</h5>
                                </Link>
                                <p>{note['detail'].slice(0, 80)}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <CarouselResponsive
                    arrayNotes={notes.slice(1, 4)}
                />
            </article> */}
        </div>
    )
}