import React from 'react'
import igicon from '../img/icono-ig-white.png'
import fbicon from '../img/icono-fb-white.png'
export default function FooterNav() {
    return (
        <div>
            <div className="footer-top bg-celeste-dark pt-3 pb-5">
                <div className="container position-relative">
                    <div className="row mapa-footer">
                        <div className="col-12 mb-5">
                            <h4></h4>
                        </div>
                        <div className="col-md-3 col-6">
                            <ul> CONTACTO
                                <li className="d-flex mt-2 mb-2">
                                    <a href="https://www.instagram.com/masqueglucosachile/" target="_blank" className="mr-2"><img src={igicon}></img></a>
                                    <a href="https://www.facebook.com/masqueglucosachile" target="_blank" className="ml-2"><img src={fbicon}></img></a>
                                </li>
                                <li><a href="mailto:hola@masqueglucosa.cl">hola@masqueglucosa.cl</a></li>
                                <li><a href="/contacto">Formulario de contacto</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-6">
                            <ul> LA CAMPAÑA
                                <li><a href="#mas-que-glucosa">Contenido informativo</a></li>
                                {/* <li><a href="#sociedades">Sociedades</a></li> */}
                            </ul>
                        </div>
                        <div className="col-md-3 col-6">
                            <ul> CONSEJOS
                                <li><a href="/#consejos">Tips de alimentación</a></li>
                                {/* <li><a href="ejercicio-fisico.html">Ejercicio físico</a></li> */}
                                {/* <li><a href="http://www.wikicardio.org.ar/" target="_blank">WikiCardio</a></li> */}
                            </ul>
                        </div>
                        <div className="col-md-3 col-6">
                            <ul> LA DIABETES
                                <li><a href="/espacio-diabetes/4">Preguntas frecuentes</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
