import React from 'react'
import {useLocation} from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux';
//import icons
import iconwp from   '../img/share-wp.png'
import iconmail from   '../img/share-mail.png'
import iconfb from   '../img/share-fb.png'
import icontw from   '../img/share-tw.png'
import iconin from   '../img/share-in.png'

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton,
  } from 'react-share';
import Noticia from './Noticia'



export default function CompartirNoticia() {

    const notes = useSelector((state) => state.notesReducer.notes, shallowEqual);
    console.log(notes);
    let location = useLocation();
    const note = notes.filter((note) => `/educacion/${note.id_note}`== location.pathname)[0]

    return (
        <div>
                <div className="compartir" id="compartir-articulo">
                        <h6>COMPARTIR</h6>

                        <div className="botones">
                            
                            <a id="share-wp">
                            <WhatsappShareButton quote={note.subtitle} children={<img src={iconwp}/>} url={`http://qa.masqueglucosa.cl/educacion/${note.id_note}`} />
                            </a>

                            <a id="share-mail">
                            <EmailShareButton quote={note.subtitle} children={<img src={iconmail}/>} url={`http://qa.masqueglucosa.cl/educacion/${note.id_note}`} />
                            </a>

                            <a id="share-fb">
                            <FacebookShareButton quote={note.subtitle}children={<img src={iconfb}/>} url={`http://qa.masqueglucosa.cl/educacion/${note.id_note}`} />
                            </a>

                            <a id="share-tw">
                            <TwitterShareButton title={note.title} via={"masqueglucosa"}  quote={note.subtitle} children={<img src={icontw}/>} url={`http://qa.masqueglucosa.cl/educacion/${note.id_note}`} />
                            </a>
                            
                            <a id="share-tw">
                            <LinkedinShareButton title={note.title} summary={note.subtitle}  children={<img src={iconin}/>} url={`http://qa.masqueglucosa.cl/educacion/${note.id_note}`} />
                            </a>
                            
                        </div>
                </div>
        </div>
    )
}

