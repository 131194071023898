import React from 'react'
import { useSelector, shallowEqual } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import Tareas from '../img/icono-tareas.png'
import Caminar from '../img/icono-caminar.png'
import Pausas from '../img/icono-pausas.png'
import IconoEscaleras from '../img/icono-escaleras.png'
import IconPausas from '../img/icono-pausas.png'
import Condicion from '../img/icono-condicion.png'
import Rutina from '../img/icono-rutina.png'
import Medicion from '../img/icono-medicion.png'
import Alimentacion from '../img/icono-alimentacion.png'
import Separador from '../img/hr-100-blanco.png'
import Frecuencia from '../img/frecuencia.jpg'
import Escaleras from '../img/running.jpg'
import NumeroUno from '../img/nro1-icon.png'
import NumeroDos from '../img/nro2-icon.png'
import NumeroTres from '../img/nro3-icon.png'
import OtrosConsejos from '../components/OtrosConsejos'
import { API_MATERIAL } from '../store/constants'




import Milqui from '../img/cover-1500kcal.jpg'

import Milocho from '../img/cover-1800kcal.jpg'




export default function Ejercicio() {

    let { slug } = useParams()

    const diets = useSelector((state) => state.dietsReducer.diets, shallowEqual);

    const otrasDietas = diets.filter((dieta) => dieta['slug'] != slug)


    return (
        <div id="ejercicio-container">
            <section id="para-todos" >
                <div className="container">
                    <h3>PARA TODOS</h3>
                    <div className="row para-todos-content">
                        <div className="d-flex col-md-6 tip">
                            <div className="image">
                                <img src={Tareas} alt="" />
                            </div>
                            <div className="content">
                                <h4>REALIZÁ MÁS TAREAS DOMÉSTICAS</h4>
                                <p>Encargate de la jardinería o realizá rutinas, programas de limpieza.</p>
                            </div>
                        </div>
                        <div className="d-flex col-md-6 tip">
                            <div className="image">
                                <img src={Caminar} alt="" />
                            </div>
                            <div className="content">
                                <h4>CAMINÁ MAS</h4>
                                <p>Si no podés caminar hasta el trabajo, dejá tu automóvil lejos de la oficina para aumentar la distancia caminada.</p>
                            </div>
                        </div>
                        <div className="d-flex col-md-6 tip">
                            <div className="image">
                                <img src={Pausas} alt="" />
                            </div>
                            <div className="content">
                                <h4>HACÉ PAUSAS ACTIVAS</h4>
                                <p>Aprovechalas para dar una pequeña caminata, estirar o realizar cualquier otra actividad en la que utilices energía.</p>
                            </div>
                        </div>
                        <div className="d-flex col-md-6 tip">
                            <div className="image">
                                <img src={IconoEscaleras} alt="" />
                            </div>
                            <div className="content">
                                <h4>USÁ LAS ESCALERAS</h4>
                                <p>Subí por las escaleras en vez de
                                    utilizar el ascensor.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="ejercicios" className="fondo-gradient-blue">
                <div className="container">
                    <div className="ejercicios-deporte">
                        <div className="">
                            <h3 className="text-white">Ejercicios y deportes</h3>
                            <h4 className="mb-4">Consultá con el médico para empezar gradualmente a ejecutar rutinas de ejercicio o deporte.<br /><br />

                                Para todo deporte que realices es importante seguir las próximas 3 etapas:</h4> <img src={Separador} className="mb-5" />
                        </div>
                        <div className="offset-md-1">
                            <ul className="etapas">
                                <li className="etapa">
                                    <img src={NumeroUno} alt="" />
                                    <p>Calentamiento de 5 a 10 minutos con ejercicios suaves de estiramiento.</p>
                                </li>
                                <li className="etapa">
                                    <img src={NumeroDos} alt="" />
                                    <p>Rutina de ejercicio de 30 a 50 minutos.</p>
                                </li>
                                <li className="etapa">
                                    <img src={NumeroTres} alt="" />
                                    <p>Enfriamiento y estiramiento.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="frecuencia" className="bg-gris">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>¿Cómo se puede lograr una actividad física adecuada?</h3> </div>
                        <div className="col-md-6"><img src={Frecuencia} /></div>
                        <div className="col-md-6 d-flex flex-column justify-content-center">
                            <p>Para saber si se está logrando una actividad física adecuada, <b>medí tu frecuencia cardíaca manualmente</b> (ubicá los dedos índice y corazón en alguna parte del cuerpo donde una arteria pase muy cerca a la piel, como en el cuello o en la muñeca) <b>o con algún dispositivo electrónico.</b> La frecuencia cardíaca durante el ejercicio debe ser un</p>
                            <div className="highlight text-celeste">
                                <p className="numero">60/80%</p>
                                <p className="texto">MÁS
                                    <br /> ALTA</p>
                            </div>
                            <p>que la frecuencia cardíaca en reposo. Por ejemplo, si tu frecuencia cardíaca en reposo es de <b>80 latidos por minuto</b>, para que el ejercicio sea adecuado, esta <b>debe estar por encima de 128 y por debajo de 144 latidos por minuto</b> durante la actividad física.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="aerobico" className="" >
                <div className="content fondo-gradient-blue">
                    <div className="container">
                        <div className="row">

                            <h3 className="offset-md-2 text-white">Ejercicio aérobico</h3>
                            <div className="offset-md-2 col-md-8 text-center text-white d-flex flex-column align-items-center">
                                <p>Para que el ejercicio aeróbico impacte en la calidad de vida, este se debe realizar al menos</p>
                                <div className="highlight">
                                    <p className="numero">3</p>
                                    <p className="texto text-left">veces por
                                        <br /> semana</p>
                                </div>
                                <p>Sin embargo, lo ideal es realizar sesiones de actividad física intensa cinco o seis días a la semana. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src={Escaleras} alt="" />
                </div>
            </section>
            <section id="consulta">
                <div className="container">

                    <h3>Consultá con tu médico</h3>
                    <h4 className="mb-5">Antes de realizar cualquier actividad es fundamental consultar con tu médico:</h4>
                    <div className=" sw-consultar">
                        <div className="swiper-wrapper  overide-swiper-wrapper">
                            <div className="row">
                                <div className="tip d-flex col-md-6">
                                    <img src={Condicion} />
                                    <p>Cuál es el ejercicio más apropiado de acuerdo a tu condición médica.</p>
                                </div>
                                <div className="tip d-flex col-md-6"> <img src={Rutina} />
                                    <p>Cómo debe ser tu rutina inicial y cómo debes ir incrementando gradualmente la duración y la intensidad.</p>
                                </div>
                                <div className="tip d-flex col-md-6"> <img src={Medicion} />
                                    <p>Cómo debés modificar tu rutina dependiendo de tus mediciones de glucosa en sangre.</p>
                                </div>
                                <div className="tip d-flex col-md-6"> <img src={Alimentacion} />
                                    <p>Cómo debes alimentarte antes y después.</p>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
            <section id="otros-consejos">
                {otrasDietas && (

                    <OtrosConsejos
                        consejos={otrasDietas.slice(0, 2)}
                        api={API_MATERIAL}
                    />

                )}
            </section>
        </div>
    )
}