import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SideNav from '../components/SideNav'
import { useSelector, shallowEqual } from 'react-redux'
import BannerTest from '../components/BannerTest'
import TestPreguntas from '../components/TestPreguntas'
import Resultado from '../components/Resultado'
import CompartirResultado from '../components/CompartiResultado'

const Resultados = [
    {
        slug: 'bajo',
        name: 'BAJO',
        title: 'SE ESTIMA QUE 1 DE CADA 100 PERSONAS DESARROLLARÁ LA ENFERMEDAD',
        text1: 'Usted no puede hacer nada respecto a su edad y su predisposición genética. Pero respecto al resto de factores que influyen en la diabetes tipo 2 como el sobrepeso, la obesidad abdominal, el estilo de vidasedentario, los hábitos alimenticios y el fumar, sí está en sus mano hacer algo. Los cambios en su estilo de vida pueden prevenir la diabetes o al menos retrasar su inicio hasta edades ya muy avanzadas',
        text2: 'Si hay personas con diabetes en su familia, tendría que vigilar el aumento de peso con los años. El aumento de la cintura, particularmente, incrementa el riesgo de diabetes tipo 2; y una actividad física moderada lo bajará. Tendría que cuidar su dieta, procurando tomar cereales ricos en fibra y verduras cada día. Elimine las grasas animales de su dieta y procure tomar en su lugar grasas vegetales.',
        text3: undefined
    },
    {
        slug: 'ligeramente-elevado',
        name: 'LIGERAMENTE ELEVADO',
        title: 'SE ESTIMA QUE 1 DE CADA 25 PERSONAS DESARROLLARÁ LA ENFERMEDAD',
        text1: 'Usted no puede hacer nada respecto a su edad y su predisposición genética. Pero respecto al resto de factores que influyen en la diabetes tipo 2 como el sobrepeso, la obesidad abdominal, el estilo de vida sedentario, los hábitos alimenticios y el fumar, sí está en sus mano hacer algo. Los cambios en su estilo de vida pueden prevenir la diabetes o al menos retrasar su inicio hasta edades ya muy avanzadas.',
        text2: 'Si hay personas con diabetes en su familia, tendría que vigilar el aumento de peso con los años. El aumento de la cintura, particularmente, incrementa el riesgo de diabetes tipo 2; y una actividad física moderada lo bajará. Tendría que cuidar su dieta, procurando tomar cereales ricos en fibra y verduras cada día. Elimine las grasas animales de su dieta y procure tomar en su lugar grasas vegetales.',
        text3: undefined
    },
    {
        slug: 'moderado',
        name: 'MODERADO',
        title: 'SE ESTIMA QUE 1 DE CADA 6 PERSONAS DESARROLLARÁ LA ENFERMEDAD',
        text1: 'Los estadios iniciales de diabetes no ocasionan síntomas. Si obtiene entre 12- 14 puntos en el cuestionario, tendría que considerar seriamente su actividad física y sus hábitos dietéticos y prestar atención a su peso, para prevenir el desarrollo de diabetes. Por favor contacte con su enfermera o su médico para un control. Usted no puede hacer nada respecto a su edad y su predisposición genética. Pero respecto al resto de factores de desarrollar diabetes tipo 2 como sobrepeso, obesidad abdominal, estilo de vida sedentario, hábitos alimenticios y fumar, sí está en su mano hacer algo. Los cambios en su estilo de vida pueden prevenir la diabetes o al menos retrasar su inicio hasta edades ya muy avanzadas',
        text2: 'Si hay personas con diabetes en su familia, tendría que vigilar el aumento de peso con los años. El aumento de la cintura, particularmente, incrementa el riesgo de diabetes tipo 2; y una actividad física moderada lo bajará.',
        text3: 'Tendría que cuidar su dieta, procurando tomar cereales ricos en fibra y verduras cada día. Elimine las grasas animales de su dieta y procure tomar en su lugar grasas vegetales.'
    },
    {
        slug: 'alto',
        name: 'ALTO',
        title: 'SE ESTIMA QUE 1 DE CADA 3 PERSONAS DESARROLLARÁ LA ENFERMEDAD',
        text1: 'Si ha obtenido 15 o más puntos en el cuestionario, tendría que hacerse un análisis de sangre para medir la glucosa (en ayunas y después de una comida) para determinar si padece una diabetes sin síntomas. ',
        text2: 'Los estadios iniciales de diabetes no ocasionan síntomas. Si obtiene entre 12- 14 puntos en el cuestionario, tendría que considerar seriamente su actividad física y sus hábitos dietéticos y prestar atención a su peso, para prevenir el desarrollo de diabetes. Por favor contacte con su enfermera o su médico para un control.',
        text3: undefined
    },
    {
        slug: 'muy-alto',
        name: 'MUY ALTO',
        title: 'SE ESTIMA QUE 1 DE CADA 2 PERSONAS DESARROLLARÁ LA ENFERMEDAD',
        text1: 'Si ha obtenido 15 o más puntos en el cuestionario, tendría que hacerse un análisis de sangre para medir la glucosa (en ayunas y después de una comida) para determinar si padece una diabetes sin síntomas.',
        text2: 'Los estadios iniciales de diabetes no ocasionan síntomas. Si obtiene entre 12- 14 puntos en el cuestionario, tendría que considerar seriamente su actividad física y sus hábitos dietéticos y prestar atención a su peso, para prevenir el desarrollo de diabetes. Por favor contacte con su enfermera o su médico para un control.',
        text3: undefined
    }
]

export default function TestDeRiesgoResult() {

    let { result } = useParams()

    const resultado = Resultados.filter((resultado) => resultado['slug'] == result)[0]
    console.log(resultado)

    const bodyClass = useSelector((state) => state.menu.bodyClass);

    return (
        <div id="calendario-actividades-interna" className={bodyClass}>

            <Navbar />
            <SideNav />

            <BannerTest />
            {/* <TestPreguntas /> */}
            <Resultado
                resultado={resultado}
            />
            <CompartirResultado />
            <div className="footer-bottom bg-gris py-5 my-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="disclaimer" id="referencia">
                                <p>
                                    1. Lindström J, Tuomilehto J. The Diabetes Risk Score: A practical tool to predict type 2 diabetes risk. Diabetes Care 2003; 26: 725-731.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
