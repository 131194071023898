import React from 'react'
import { Helmet } from 'react-helmet'

export default function SEO() {
    return (
        <Helmet>
               
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.masqueglucosa.com.ar/"/>
        <meta property="og:title" content="Más que glucosa"/>
        <meta property="og:description" content="Diabetes: Hay órganos que sufren y no se ven"/>
        
        </Helmet>
        
        )

        
}
