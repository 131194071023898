import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../css/swiper.min.css';
import SideNav from '../components/SideNav'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import SEO from '../components/SEO'


export default function Container404() {

    const bodyClass = useSelector((state) => state.menu.bodyClass);
    console.log(bodyClass);

    return (
        <div id="error404">
            <Helmet>
                <title>Más Que Glucosa | 404</title>
                <meta name="description" content="Más que glucosa argentina"/>
                <SEO />
            </Helmet>
            <div className={bodyClass}>
                <Navbar />
                <SideNav />
                <main>
                    <h1 className="text-center">La página no existe</h1>
                    <p className="text-center my-3 my-lg-4">La dirección que usted intenta buscar no existe</p>
                    <p className="d-flex justify-content-center align-items-center">
                        <a className="btn" href="/">Volver al inicio</a>
                    </p>
                </main>
                <Footer />
            </div>
        </div>
    )
}
