import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {
    Link,
    useParams
} from "react-router-dom";

import CarouselResponsive from './CarouselResponsive'

import CompartirButton from './CompartirButton';

// PARSEA EL HTML QUE VIENE DEL BACK
import ReactHtmlParser from 'react-html-parser'

// API RUTA IMAGENES
import { API_MATERIAL_NOTES_FILES } from '../store/constants'
import MultiCarousel from './MultiCarousel';



export default function TuEspacioDeDiabetesNota() {

    // NOTAS
    const notes = useSelector(state => state.notesReducer.notes, shallowEqual)


    //SELECCIONO LA NOTA
    let { id } = useParams();
    const note = notes ? notes.filter(note => note.id_note === id)[0] : {};

    const otrasNotas = notes.filter(note => note.id_note != id)

    return (
        <div  >
            <article className="nota-principal">
                <div className="background-gradient">
                    <span className="sr-only">Background</span>
                </div>
                <div className="container">
                    <div className="header">
                        <p className="miga-pan" >
                            <Link to='/espacio-diabetes'>
                                TU ESPACIO DE DIABETES
                           </Link>
                            {" "}/ NOTA</p>
                        <h3>TU ESPACIO DE DIABETES</h3>
                    </div>
                    {note && (
                        <div className="nota-content">
                            <h4 className="desktop-title" >{note['title']}</h4>
                            <img src={`${API_MATERIAL_NOTES_FILES}${note["file_desktop"]}`} alt="" />
                            <div className="container-button-compartir" >
                                <CompartirButton
                                    url={window.location.href}
                                    title={note['title']}
                                    body={'Para ver la nota haga click en la siguiente URL'}
                                />
                            </div>
                            <h4 className="mobile-title" >{note['title']}</h4>
                            <p className="detail" >{ReactHtmlParser(note['detail'])}</p>
                        </div>
                    )}
                </div>
            </article>
            {otrasNotas && (
                <CarouselResponsive
                    arrayNotes={otrasNotas}
                />
            )}
            {otrasNotas && (
                <article className="otras-notas">
                    <div className="container">
                        <h3>TAMBIÉN TE PUEDE INTERESAR</h3>
                        <div >
                            <MultiCarousel
                                notes={otrasNotas}
                            />
                        </div>
                        <CarouselResponsive
                            arrayNotes={otrasNotas}
                        />
                    </div>
                </article>
            )}
        </div>
    )
}