import React from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SideNav from '../components/SideNav'
import DesMerCol from '../components/DesMerCol'
import Almuerzo from '../components/Almuerzo'
import TipsRandom from '../components/TipsRandom'
import { useLocation } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux';
import Tips from '../components/Tips'
import { Helmet } from 'react-helmet'
import ConsejosHeader from '../components/ConsejosHeader'
import { API_MATERIAL } from '../store/constants'
import OtrosConsejos from '../components/OtrosConsejos'
import Ejercicio from '../components/Ejercicio'

export default function TipsContainer() {

    let { slug } = useParams()

    const bodyClass = useSelector((state) => state.menu.bodyClass);

    const diets = useSelector((state) => state.dietsReducer.diets, shallowEqual);

    const otrasDietas = diets ? diets.filter((dieta) => dieta['slug'] != slug) : [];

    let location = useLocation();

    const diet = diets ? diets.filter((diet) => diet['slug'] == slug)[0] : {};

    console.log(diet)



    return (

        <div>
            <Helmet>
                <title>Dietas | Mas Que Glucosa</title>
                <meta name="description"></meta>
            </Helmet>

            <div className={bodyClass}>
                <Navbar />

                <SideNav />

                <div className="container consejos-header-title">
                    <div id="header-interna">
                        <p style={{ textTransform: 'uppercase' }} className="miga-pan" >{`CONSEJOS ÚTILES / ${diet && diet['title']}`}</p>
                        <h3>CONSEJOS ÚTILES</h3>
                    </div>

                </div>

                {diet && (
                    <ConsejosHeader
                        consejo={diet}
                    />
                )}

                {/* <section id="navtips" className="header-interna">
                </section>| */}

                {diet && (
                    <div  >
                        {diet['checkbox'] == 1 && (
                            <Ejercicio />
                        )}
                    </div>
                )}

                {diet && (
                    <div>
                        {diet['checkbox'] != 1 && (
                            <section id="dmc" >
                                <div class="container">
                                    <div class="row">
                                        <DesMerCol
                                            title={"DESAYUNO"}
                                            api={API_MATERIAL}
                                            foot={diet['breakfast']}
                                            foot2={diet['breakfast2']}
                                            foot_description={diet['breakfast_description']}
                                            foot_description2={diet['breakfast_description2']}
                                            foot_file={diet['breakfast_file']}
                                            foot_file2={diet['breakfast_file2']}
                                        />

                                        <DesMerCol
                                            title={"MERIENDA"}
                                            api={API_MATERIAL}
                                            foot={diet['collation']}
                                            foot2={diet['collation2']}
                                            foot_description={diet['collation_description']}
                                            foot_description2={diet['collation_description2']}
                                            foot_file={diet['collation_file']}
                                            foot_file2={diet['collation_file2']}
                                        />

                                        <DesMerCol
                                            title={"COLACIÓN"}
                                            api={API_MATERIAL}
                                            foot={diet['snack']}
                                            foot2={diet['snack2']}
                                            foot_description={diet['snack_description']}
                                            foot_description2={diet['snack_description2']}
                                            foot_file={diet['snack_file']}
                                            foot_file2={diet['snack_file2']}
                                        />

                                        {/*
                                <TipsRandom /> */}

                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                )}


                {diet && diets && (
                    <div>
                        {diet['checkbox'] != 1 && (
                            <Almuerzo
                                diet={diets[0]}
                            />
                        )}
                    </div>
                )}

                <section id="otros-consejos">
                    {diet && (
                        <div>
                            {diet['checkbox'] != 1 && (
                                <OtrosConsejos
                                    consejos={otrasDietas.slice(0, 2)}
                                    api={API_MATERIAL}
                                />
                            )}
                        </div>
                    )}
                </section>

                <Tips />


                <Footer />
            </div>
        </div>
    )
}
