
import separadorCeleste from '../img/hr-100-celeste.png'

import { useSelector, shallowEqual } from 'react-redux';
import { API_MATERIAL, URL } from '../store/constants';

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function NoticiasRandom() {

    const notes = useSelector((state) => state.notesReducer.notes, shallowEqual);

    return (
        <div className="container">
            <section className="row faq-diabetes-grilla">
                    <header className="col-12 seccion">
                    <h3 className="text-celeste">Más artículos</h3> <img src={separadorCeleste}/> 
                    </header>
                  
                        
                        {notes.map((nota) => (
                         <div className="col-md-4 articulo">
                         
                             <div className="imagen gradient" style={{backgroundImage: `url(${API_MATERIAL}/notes/media/${nota['file_desktop']})`}}></div>
                             <Link to={`/educacion/${nota.id_note}`}> <h4>{nota.title}</h4> </Link>
                             <p>{nota.subtitle}</p> 
                             <Link className="leer-mas" to={`/educacion/${nota.id_note}`}>SEGUIR LEYENDO</Link> 
                         </div>
                        
                         ))}
                       
                   

               
            </section>
        </div>
    )
}
