import {
    FETCH_DIETS
  } from "../constants";
  
  const initialState = {
    tips: [],
  };





export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DIETS:
            return Object.assign({}, state, {
                diets: action.diets
            });
        default:
            return state;
    }
}