import {
    SET_MENU_CLASS
} from "../constants";

const initialState = {
    homeClass: 'home',
    internaClass: 'interna',
    overlayClass: 'overlay',
    buttonTogglerClass: 'navbar-toggler',
    menuClass: 'menu-principal',
    bodyClass:'',
    buttonActiveClass:'',
    
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_CLASS:
            return {
                ...state, ...action.data
            }
            default:
                return state;
    }
};