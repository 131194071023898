import React from 'react';
import Default from "../containers/Default";
import { Helmet } from 'react-helmet';
import '../css/dia-mundial-diabetes-2022.css';
import logosHeader from '../img/dia-logos-header.svg';
import mujerPinchandose from '../img/mujer-pinchandose.svg';
import ojo from '../img/iconos-dia-mundial-diabetes-2022/ojo.png';
import mente from '../img/iconos-dia-mundial-diabetes-2022/mente.png';
import cosas from '../img/iconos-dia-mundial-diabetes-2022/cosas.png';
import pierna from '../img/iconos-dia-mundial-diabetes-2022/pierna.png';
import pancreas from '../img/iconos-dia-mundial-diabetes-2022/pancreas.png';
import pulmones from '../img/iconos-dia-mundial-diabetes-2022/rinones.png';
import corazon from '../img/iconos-dia-mundial-diabetes-2022/corazon.png';
import medicaPaciente from '../img/medica-paciente.svg';
import medicaViendo from '../img/medico-viendo.png';

function DiaDiabetes() {
    return (
        <Default>
            <Helmet>
                <title>Día Mundial de la Diabetes | Mas Que Glucosa</title>
            </Helmet>
            <h1 className="sr-only">Día Mundial de la Diabetes</h1>
            <div id="dia-mundial-diabetes">
                <div className="bg-naranja-oscuro">
                    <div className="container">
                        <img src={logosHeader} alt="International diabetes federation. Día mundial de la diabetes" className={"img-fluid mx-auto text-center"}/>
                    </div>
                </div>
                <section className="bg-naranja-soft pb-0">
                    <h2 className="titular text-white impulsa"><span>¿Cuándo fue la última vez que aprendió algo nuevo sobre la diabetes?</span></h2>
                    <div className="container">
                        <div className="row row-cols-md-2 position-relative">
                            <div className="col-8 col-md-6 d-flex justify-content-center align-items-center flex-column py-5 py-md-0 z-10">
                                <p>Actualmente, la diabetes afecta a más de <strong>500 millones de personas</strong> en todo el mundo.</p>
                                <p className="mt-3"><b>Casi la mitad</b> no saben que la tienen, lo que les expone a un mayor riesgo de <b>complicaciones graves</b>.</p>
                            </div>
                            <div className="col-md-6 col-4 position-mobile-static">
                                <img id={"pinchado"} src={mujerPinchandose} alt="Mujer midiendo su glucosa" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="bloque-azul">
                            <figure>
                                <picture>
                                    <img src={corazon} alt="Corazón ícono" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Ataque <br/> Cardíaco</figcaption>
                            </figure>
                            <figure>
                                <picture>
                                    <img src={pulmones} alt="Pulmones ícono" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Insuficiencia <br/> renal</figcaption>
                            </figure>
                            <figure>
                                <picture>
                                    <img src={pancreas} alt="" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Daño <br/> hepático</figcaption>
                            </figure>
                            <figure>
                                <picture><img src={pierna} alt="Pierna amputada ícono" className="img-fluid"/></picture>
                                <figcaption className={"text-white"}>Amputación <br/>de miembros <br/>Inferiores</figcaption>
                            </figure>
                            <figure>
                                <picture>
                                    <img src={cosas} alt="Espermatozoides íconos" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Disfunción <br/> sexual</figcaption>
                            </figure>
                            <figure>
                                <picture>
                                    <img src={mente} alt="Mente ícono" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Accidente <br/> cerebrovascular</figcaption>
                            </figure>
                            <figure>
                                <picture>
                                    <img src={ojo} alt="Ojo ícono" className="img-fluid"/>
                                </picture>
                                <figcaption className={"text-white"}>Pérdida de <br/> visión</figcaption>
                            </figure>
                        </div>
                        <div className="my-5 py-md-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <h3 className={"sr-only"}>1 de cada 10 <br/><span className={"text-uppercase"}>personas tiene diabetes</span></h3>
                                    <div className={"d-flex justify-content-center align-items-center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="314" height="288" viewBox="0 0 314 288">
                                            <g id="Grupo_4556" data-name="Grupo 4556" transform="translate(-292 -1621)">
                                                <text id="personas_tiene_diabetes_" data-name="personas tiene
diabetes " transform="translate(449 1830)" fill="#fb9c57" font-size="73" font-family="'Impulsa', sans-serif"><tspan x="-156.329" y="0">PE</tspan><tspan y="0" letter-spacing="0.005em">R</tspan><tspan y="0">SONAS TIENE</tspan><tspan x="-95.885" y="62">DIABETES </tspan></text>
                                                <g id="Grupo_4382" data-name="Grupo 4382" transform="translate(-66.207)">
                                                    <text id="de_" data-name="de " transform="translate(473.207 1701.22)" fill="#fb9c57" font-size="42" font-family="'Impulsa', sans-serif"><tspan x="0" y="0">de </tspan></text>
                                                    <text id="cada" transform="translate(462.207 1741.962)" fill="#fb9c57" font-size="42" font-family="'Impulsa', sans-serif"><tspan x="0" y="0">cada</tspan></text>
                                                    <text id="_10" data-name="10" transform="translate(526.394 1751)" fill="#fb9c57" font-size="152" font-family="'Impulsa', sans-serif"><tspan x="0" y="0">10</tspan></text>
                                                    <text id="_1" data-name="1" transform="translate(420.02 1751.178)" fill="#fb9c57" font-size="152" font-family="'Impulsa', sans-serif"><tspan x="0" y="0">1</tspan></text>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="col-md-8 d-flex align-items-center">
                                    <p><b>La creciente prevalencia de diabetes</b> somete a los sistemas sanitarios a una presión cada vez mayor, impidiendo que las personas con diabetes tengan <strong>acceso a la atención y educación que necesitan</strong>.</p>
                                </div>
                            </div>
                        </div>
                        <div className="bloque-naranja mt-5">
                            <div className="row">
                                <div className="col-md-6 pb-4 pb-md-0">
                                    <h3 className="text-white pl-md-4 pr-md-4 mb-3 impulsa text-md-left text-center">SÍNTOMAS MÁS COMUNES:</h3>
                                    <ul>
                                        <li className="text-white">Visión borrosa</li>
                                        <li className="text-white">Sed excesiva</li>
                                        <li className="text-white">Pérdida de peso involuntaria</li>
                                        <li className="text-white">Micción frecuente</li>
                                        <li className="text-white">Falta de energía</li>
                                        <li className="text-white">Cicatrización de heridas lenta</li>
                                        <li className="text-white">Infecciones frecuentes</li>
                                        <li className="text-white">Hormigueo o entumecimiento en manos y pies</li>
                                    </ul>
                                    <p className="mt-3 text-white pl-md-4"><b>Estos síntomas pueden ser leves o estar
                                        ausentes en personas con diabetes tipo 2.</b></p>
                                </div>
                                <div className="col-md-6 hr pt-4 pt-md-0">
                                    <h3 className="text-white pl-md-4 pr-md-4 mb-3 impulsa text-md-left text-center">FACTORES DE RIESGO DE LA
                                        DIABETES | tipo 2:</h3>
                                    <ul>
                                        <li className="text-white">Antecedentes familiares de diabetes</li>
                                        <li className="text-white">Hábitos alimenticios poco saludables</li>
                                        <li className="text-white">Falta de actividad física</li>
                                        <li className="text-white">Sobrepeso</li>
                                        <li className="text-white">Edad</li>
                                        <li className="text-white">Presión arterial alta</li>
                                        <li className="text-white">Origen étnico</li>
                                        <li className="text-white">Antecedentes de diabetes gestacional</li>
                                        <li className="text-white">Mala nutrición durante el embarazo </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <aside className="bg-celeste pt-md-2 pt-5">
                    <div className="row mx-0 flex-column-reverse flex-md-row">
                        <div className="col-md-4 px-0">
                            <img src={medicaPaciente} alt="Medica con paciente" className={"img-fluid"}/>
                        </div>
                        <div className="col-md-7">
                            <div className="container d-flex justify-content-center flex-column h-100">
                                <p className={"text-center text-md-left"}>Las personas con diabetes <b>autogestionan</b> sus cuidados
                                    más del <b>95% del tiempo</b>.</p>
                                <p className="mt-3 text-center text-md-left">Es necesario que entiendan su condición y <strong>actualicen sus conocimientos sobre diabetes</strong> para poder manejarla.</p>
                                <h2 className="texto-azul mt-3 text-center text-md-left impulsa">Educar hoy para proteger el futuro</h2>
                            </div>
                        </div>
                    </div>
                </aside>
                <section id={"educar"} className={"bg-naranja-soft pb-0"}>
                    <h2 className="titular impulsa"><span>Diabetes: educar para proteger el futuro</span></h2>
                    <div className="row mt-5 mx-0">
                        <div className="col-md-7 d-flex flex-column justify-content-center">
                            <div className="col-md-8 ml-md-auto py-5">
                                <p><strong>La creciente prevalencia de diabetes</strong> somete a los profesionales sanitarios a una presión cada vez mayor. Tienen que aprovechar al máximo el limitado tiempo del que disponen para proporcionar <strong>los mejores consejos y cuidados posibles</strong>  para la millones de personas que viven con diabetes.</p>
                                <p className="mt-3 pb-5">Los gobiernos se han comprometido con unos <strong>objetivos ambiciosos</strong> que guiarán su acciones en relación con la diabetes durante la <b>próxima década</b>. <br/>Invertir en <strong>la formación de los profesionales sanitarios</strong> es esencial para <strong>mejorar las tasas de diagnóstico de diabetes  y la calidad de vida</strong> de las personas que viven con ella.</p>
                                <p className="mt-3 pb-5">Los gobiernos se han comprometido con unos <strong>objetivos ambiciosos</strong> que guiarán su acciones en relación con la diabetes durante la <b>próxima década</b>. <br/>Invertir en <strong>la formación de los profesionales sanitarios</strong> es esencial para <strong>mejorar las tasas de diagnóstico de diabetes  y la calidad de vida</strong> de las personas que viven con ella.</p>
                            </div>
                        </div>
                        <div className="col-md-5 px-0 justify-content-md-end align-items-md-end d-flex">
                            <img src={medicaViendo} alt="medico inspeccionando" className="img-fluid ml-md-auto" style={{maxWidth: 600}}/>
                        </div>
                    </div>
                </section>
                <div className="d-flex flex-md-nowrap flex-wrap">
                    <article className={"bg-naranja px-md-5 px-3 py-5 w-100 d-flex justify-content-center align-items-center"}>
                        <h2 className="sr-only">1 de cada 9<br/>PERSONAS TENDRÁ DIABETES EN 2030</h2>
                        <div className="d-flex justify-content-center align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="357" height="350" viewBox="0 0 357 350">
                                <g id="Grupo_4555" data-name="Grupo 4555" transform="translate(-266 -4785)">
                                    <text id="personas_TENDRÁ_DIABETES_EN_2030" data-name="personas TENDRÁ
DIABETES EN
2030" transform="translate(444 4994)" fill="#fff" font-size="73" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="-177.755" y="0">PE</tspan><tspan y="0" letter-spacing="0.005em">R</tspan><tspan y="0">SONAS TENDRÁ</tspan><tspan x="-121.143" y="62">DIABETES EN</tspan><tspan x="-50.333" y="124">2030</tspan></text>
                                    <g id="Grupo_4465" data-name="Grupo 4465" transform="translate(-53.02 3164)">
                                        <text id="de_" data-name="de " transform="translate(473.207 1701.22)" fill="#fff" font-size="42" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">de </tspan></text>
                                        <text id="cada" transform="translate(462.207 1741.962)" fill="#fff" font-size="42" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">cada</tspan></text>
                                        <text id="_9" data-name="9" transform="translate(526.394 1751)" fill="#fff" font-size="152" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">9</tspan></text>
                                        <text id="_1" data-name="1" transform="translate(420.02 1751.178)" fill="#fff" font-size="152" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">1</tspan></text>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </article>
                    <article className={"bg-celeste px-md-5 px-3 py-5 w-100 d-flex justify-content-center align-items-center"}>
                        <h2 className="sr-only">Casi 1 de cada 2<br/>PERSONAS CON DIABETES NO ESTÁ DIAGNOSTICADA</h2>
                        <div className="d-flex justify-content-center align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="352" height="386" viewBox="0 0 352 386">
                                <g id="Grupo_4554" data-name="Grupo 4554" transform="translate(-268 -5300)">
                                    <g id="Grupo_4466" data-name="Grupo 4466" transform="translate(-51.02 3715)">
                                        <text id="de_" data-name="de " transform="translate(473.207 1701.22)" fill="#00a4e2" font-size="42" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">de </tspan></text>
                                        <text id="cada" transform="translate(462.207 1741.962)" fill="#00a4e2" font-size="42" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">cada</tspan></text>
                                        <text id="_2" data-name="2" transform="translate(526.394 1751)" fill="#00a4e2" font-size="152" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">2</tspan></text>
                                        <text id="_1" data-name="1" transform="translate(420.02 1751.178)" fill="#00a4e2" font-size="152" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">1</tspan></text>
                                    </g>
                                    <text id="CASI" transform="translate(408 5357)" fill="#00a4e2" font-size="67" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="0" y="0">CASI</tspan></text>
                                    <text id="personas_CON_DIABETES_NO_ESTÁ_DIAGNOSTICADA" data-name="personas CON
DIABETES NO ESTÁ
DIAGNOSTICADA" transform="translate(444 5545)" fill="#00a4e2" font-size="73" font-family="Impulsa-Regular, 'Impulsa', sans-serif"><tspan x="-138.408" y="0">PE</tspan><tspan y="0" letter-spacing="0.005em">R</tspan><tspan y="0">SONAS CON</tspan><tspan x="-175.309" y="62">DIABETES NO ESTÁ</tspan><tspan x="-146.839" y="124">DIAGNOSTICADA</tspan></text>
                                </g>
                            </svg>
                        </div>
                    </article>
                </div>
                <section id={"objetivos"} className="bg-celeste-soft pt-5 mb-0 pb-0">
                    <h2 className={"sr-only"}>Objetivos</h2>
                    <div className="container">
                        <div className="bg-azul border-20 py-5 px-md-5 px-3">
                            <h3 className="text-white text-center text-md-left impulsa">OBJETIVOS DE COBERTURA DE DIABETES DE LA OMS A ALCANZAR PARA 2030:</h3>
                            <ul id={"objetivos"} className={"mt-5"}>
                                <li className={"text-white"}><span className={"impulsa"}>Objetivo 1:</span> El <b>80%</b> de las personas con diabetes son diagnosticadas.</li>
                                <li className={"text-white mt-4"}><span className={"impulsa"}>Objetivo 2:</span> El <b>80%</b> de las personas con diabetes diagnosticada tiene un buen control de su glucemia.</li>
                                <li className={"text-white mt-4"}><span className={"impulsa"}>Objetivo 3:</span> El <b>80%</b> de las personas con diabetes diagnosticada tiene un buen control de su presión arterial.</li>
                                <li className={"text-white mt-4"}><span className={"impulsa"}>Objetivo 4:</span> El <b>100%</b>  de las personas con diabetes tipo 1 tiene acceso a insulina y autocontrol de glucosa en sangre asequibles.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <aside className="bg-naranja-soft py-5">
                    <div className="container">
                        <h2 className={"texto-naranja text-center impulsa"}>¿Cuándo fue la última vez que actualizó su conocimiento? </h2>
                        <p className="texto-naranja mt-3 text-center impulsa">PromoMats ID: CL-4018.</p>
                    </div>
                </aside>
            </div>
        </Default>
    );
}

export default DiaDiabetes;