import React from 'react'
import IconoDeNuevo from "../img/test-de-nuevo.png";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import Footer from "./Footer";

export default function TestError(props){
    return(
        <div id="test-error">
            <Navbar />
            <SideNav />
            <div className="container-fluid py-5 mt-3">
                <div className="container">
                    <h2 className="h1 text-center">¡Hubo un problema con la validación!</h2>
                    <p className="text-center">Hubo un problema al momento de validar los datos de su formulario, por favor vuelva a intentarlo</p>
                    <p className="my-5 d-flex justify-content-center align-items-center">
                        <a className="btn" href="/test-de-riesgo">VOLVER AL TEST</a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}