import React, { useState } from 'react'
import ProgressBar from './ProgressBar'
import {useHistory} from 'react-router-dom'

//Numeros Iconos
import PreguntaUno from '../img/test-1.png'
import PreguntaDos from '../img/test-2.png'
import PreguntaTres from '../img/test-3.png'
import PreguntaCuatro from '../img/test-4.png'
import PreguntaCinco from '../img/test-5.png'
import PreguntaSeis from '../img/test-6.png'
import PreguntaSiete from '../img/test-7.png'
import PreguntaOcho from '../img/test-8.png'
import marca from '../img/marca.png'

const InitialValues = {
    rango_edad: '',
    indice_corporal: '',
    perimetro_cintura: '',
    actividad_fisica: '',
    verduras: '',
    medicacion: '',
    glucosa: '',
    familiares_diabetes: ''
}

const initialRespuestasClases = {
    rango_edad: 'disabled',
    indice_corporal: 'disabled',
    perimetro_cintura: 'disabled',
    actividad_fisica: 'disabled',
    verduras: 'disabled',
    medicacion: 'disabled',
    glucosa: 'disabled',
    familiares_diabetes: 'disabled'
}


export default function TestPreguntas() {

    let history = useHistory()

    const [completed, setCompleted] = useState(0);

    const [respuestasValues, setRespuestasValues] = useState(InitialValues);

    const [respuestasClases, setRespuestasClases] = useState(initialRespuestasClases);

    const [questionNumber, setQuestionNumber] = useState(1)

    const initialCalculadoraValues = {
        altura: '',
        peso: ''
    }

    const [calculadoraValues, setCalculadoraValues] = useState(initialCalculadoraValues)

    const handleInputChange = (event) => {
        setCalculadoraValues({
            ...calculadoraValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleInputValuesChange = (event) => {
        console.log(event.target)
        console.log("INPUT CHANGE")
        setRespuestasValues({
          ...respuestasValues,
          [event.target.name]: event.target.value,
        });
      };

    const redirectToResult = (result) => {
        history.push(`/test-de-riesgo/${result}`)
    }

    const redirectToError = (result) => {
        history.push(`/test-${result}`)
    }

    Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
        return this
    };

    const calculadora = (altura, peso) => {
        if (altura && peso) {
            let nuevaAltura = Number(altura.split("").insert(1, ".").join(""))
            let nuevoPeso = Number(peso)
            let result = nuevoPeso / (nuevaAltura * nuevaAltura)
            return Number(result.toString().split("").slice(0, 5).join(""))
        } else {
            return false
        }
    }

    const nextQuestion = (question, barProgress, nextQuestionNumber) => {
        if(respuestasValues[question] === ''){
            let val = 'active'
            setRespuestasClases((prevState) => {
                return {...prevState, [question]: val};
            });
        } else {
            let val = 'disabled'
            setRespuestasClases((prevState) => {
                return {...prevState, [question]: val};
            });
            setQuestionNumber(nextQuestionNumber);
            setCompleted(barProgress);
        }
    }

    const finishTest = () => {

        if(respuestasValues['familiares_diabetes'] === ''){
            let val = 'active'
            setRespuestasClases((prevState) => {
                return {...prevState, ['familiares_diabetes']: val};
            });
        } else {
            let finalValues = []

            let rango_edad_value;
            switch(respuestasValues['rango_edad']){
                case 'menos_45':
                    rango_edad_value = 0
                    break;
                case 'entre_45_54':
                    rango_edad_value = 2
                    break;
                case 'entre_55_64':
                    rango_edad_value = 3
                    break;
                case 'mas_64':
                    rango_edad_value = 5
                    break;
                default:
                }
                finalValues.push(rango_edad_value);

            let indice_corporal_value;
            switch(respuestasValues['indice_corporal']){
                case 'menor_25_kg':
                    indice_corporal_value = 0
                    break;
                case 'entre_25_30_kg':
                    indice_corporal_value = 1
                    break;
                case 'mayor_30_kg':
                    indice_corporal_value = 3
                    break;
                default:
                }
                finalValues.push(indice_corporal_value)

            let perimetro_cintura_value;
            switch(respuestasValues['perimetro_cintura']){
                case 'menos_94_cm':
                case 'menos_80_cm':
                    perimetro_cintura_value = 0
                    break;
                case 'entre_94_102_cm':
                case 'entre_80_88_cm':
                    perimetro_cintura_value = 3
                    break;
                case 'mas_102_cm':
                case 'mas_88_cm':
                    perimetro_cintura_value = 4
                    break;
                default:
                }
                finalValues.push(perimetro_cintura_value)

            let actividad_fisica_value;
            switch(respuestasValues['actividad_fisica']){
                case 'si':
                    actividad_fisica_value = 0
                    break;
                case 'no':
                    actividad_fisica_value = 2
                    break;
                default:
            }
            finalValues.push(actividad_fisica_value)

            let verduras_value;
            switch(respuestasValues['verduras']){
                case 'si':
                    verduras_value = 0
                    break;
                case 'no':
                    verduras_value = 1
                    break;
                default:
            }
            finalValues.push(verduras_value)

            let medicacion_value;
            switch(respuestasValues['medicacion']){
                case 'no':
                    medicacion_value = 0
                    break;
                case 'si':
                    medicacion_value = 2
                    break;
                default:
            }
            finalValues.push(medicacion_value)

            let glucosa_value;
            switch(respuestasValues['glucosa']){
                case 'no':
                    glucosa_value = 0
                    break;
                case 'si':
                    glucosa_value = 5
                    break;
                default:
            }
            finalValues.push(glucosa_value)

            let familiares_diabetes_value;
            switch(respuestasValues['familiares_diabetes']){
                case 'no':
                    familiares_diabetes_value = 0
                    break;
                case 'abuelos_tios_primos':
                    familiares_diabetes_value = 3
                    break;
                case 'padres_hermanos_hijos':
                    familiares_diabetes_value = 4
                    break;
                default:
            }
            finalValues.push(familiares_diabetes_value)

            console.log(finalValues)

            const reducer = (accumulator, currentValue) => accumulator + currentValue;

            const valueTest = finalValues.reduce(reducer);
            console.log(valueTest)
            setCompleted(100)

            let resultadoSlug;
            if(valueTest >= 0 && valueTest <=6) {resultadoSlug = 'bajo';}
            else if (valueTest >= 7 && valueTest <= 11) {resultadoSlug = 'ligeramente-elevado';}
            else if(valueTest >= 12 && valueTest <= 14) {resultadoSlug = 'moderado';}
            else if(valueTest >= 15 && valueTest <= 19) {resultadoSlug = 'alto';}
            else if (valueTest > 21){ resultadoSlug = 'muy-alto';}
            else {resultadoSlug = 'error'}

            console.log(resultadoSlug)

            if (resultadoSlug !== 'error'){
                redirectToResult(resultadoSlug)
            } else {
                redirectToError(resultadoSlug)
            }


            console.log("TERMINO EL TEST")

        }

    }

    console.log(calculadora('181', '98'))
    console.log(calculadora('', ''))
    console.log(respuestasValues)


    return (
        <div className="test-preguntas-container">
            <div className="container">
                <ProgressBar
                    bgcolor={"#6a1b9a"} completed={completed}
                />
                {questionNumber == 1 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaUno} alt="" />
                            <p>¿EN QUÉ RANGO DE EDAD SE ENCUENTRA?</p>
                        </div>
                        <div className="inputs">
                            <li>
                                <label className="container-label" for="menos_45_anos">
                                    <input
                                    type="radio"
                                    id="menos_45_anos"
                                    name="rango_edad"
                                    value="menos_45"
                                    checked={respuestasValues['rango_edad'] === "menos_45"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                Menos de 45 años
                            </label>
                            </li>
                            <li>
                                <label className="container-label" for='entre_45_54_anos'>
                                    <input
                                    type="radio"
                                    id="entre_45_54_anos"
                                    name="rango_edad"
                                    value="entre_45_54"
                                    checked={respuestasValues['rango_edad'] === "entre_45_54"}
                                    onChange={handleInputValuesChange}
                                      />
                                    <span className="checkmark"></span>
                                45-54 años
                            </label>
                            </li>
                            <li>
                                <label className="container-label" for="entre_55_64_anos">
                                <input
                                    type="radio"
                                    id="entre_55_64_anos"
                                    name="rango_edad"
                                    value="entre_55_64"
                                    checked={respuestasValues['rango_edad'] === "entre_55_64"}
                                    onChange={handleInputValuesChange}
                                      />
                                    <span className="checkmark"></span>
                                55-64 años
                            </label>
                            </li>
                            <li>
                                <label className="container-label" for="mas_64_anos">
                                <input
                                    type="radio"
                                    id="mas_64_anos"
                                    name="rango_edad"
                                    value="mas_64"
                                    checked={respuestasValues['rango_edad'] === "mas_64"}
                                    onChange={handleInputValuesChange}
                                      />
                                    <span className="checkmark"></span>
                                Más de 64 años
                            </label>
                            </li>
                        </div>
                        <p className={respuestasClases['rango_edad']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <p>pregunta 1 de 8</p>
                            <button onClick={() => {
                                nextQuestion('rango_edad',10,2);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}

                {questionNumber == 2 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaDos} alt="" />
                            <p className="title" >ÍNDICE DE MASA CORPORAL</p>
                        </div>
                        <div className="calculadora-container">
                            <div className="row">
                                <div className="calculadora col-md-6">
                                    <p className="subtitle" >Calculadora índice de masa corporal</p>
                                    <label for="altura" htmlFor="">
                                        Mi altura es cm
                                <input onChange={handleInputChange} value={calculadoraValues['altura']} name="altura" id="altura" type="number" />
                                    </label>
                                    <label for="peso" htmlFor="">
                                        Mi peso es kg
                                <input onChange={handleInputChange} value={calculadoraValues['peso']} name="peso" id="peso" type="number" />
                                    </label>
                                    {calculadora(calculadoraValues['altura'], calculadoraValues['peso']) && (
                                        <div className={`calculadora-resultado ${calculadora(calculadoraValues['altura'], calculadoraValues['peso']) ? 'show' : 'hide'}`}>
                                            <span className="result-numer">
                                                {calculadora(calculadoraValues['altura'], calculadoraValues['peso'])}kg/m2
                                        </span>
                                            {calculadora(calculadoraValues['altura'], calculadoraValues['peso']) < 25 && (
                                                <p>Seleccionar "Menor de 25 kg/m2"</p>
                                            )}
                                            {calculadora(calculadoraValues['altura'], calculadoraValues['peso']) >= 25 && calculadora(calculadoraValues['altura'], calculadoraValues['peso']) <= 30 && (
                                                <p>Seleccionar "Entre de 25-30 kg/m2"</p>
                                            )}
                                            {calculadora(calculadoraValues['altura'], calculadoraValues['peso']) > 30 && (
                                                <p>Seleccionar "Mayor de 30 kg/m2"</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 inputs">
                                    <p className="subtitle" >Índice de masa corporal</p>
                                    <li>
                                        <label className="container-label" for="menor_25_kg">
                                        <input
                                    type="radio"
                                    id="menor_25_kg"
                                    name="indice_corporal"
                                    value="menor_25_kg"
                                    checked={respuestasValues['indice_corporal'] === "menor_25_kg"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                Menor de 25 kg/m2
                            </label>
                                    </li>
                                    <li>
                                        <label className="container-label" for="entre_25_30_kg">
                                        <input
                                    type="radio"
                                    id="entre_25_30_kg"
                                    name="indice_corporal"
                                    value="entre_25_30_kg"
                                    checked={respuestasValues['indice_corporal'] === "entre_25_30_kg"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                Entre 25-30 kg/m2
                            </label>
                                    </li>
                                    <li>
                                        <label className="container-label" for="mayor_30_kg">
                                        <input
                                    type="radio"
                                    id="mayor_30_kg"
                                    name="indice_corporal"
                                    value="mayor_30_kg"
                                    checked={respuestasValues['indice_corporal'] === "mayor_30_kg"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                Mayor de 30 kg/m2
                            </label>
                                    </li>

                                </div>
                            </div>
                        </div>
                        <p className={respuestasClases['indice_corporal']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(1)
                                    setCompleted(0)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 2 de 8</p>
                            <button onClick={() => {
                                nextQuestion('indice_corporal',20,3);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}
                {questionNumber == 3 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaTres} alt="" />
                            <div>
                                <p>PERÍMETRO DE CINTURA</p>
                                <p className="copete text-yellow">
                                    medido por debajo las costillas
                         </p>
                                <p className="text-yellow" >(normalmente a nivel del ombligo)</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 inputs row">
                                <div className="col-md-6 hombres">
                                    <p className="subtitle">Hombres</p>
                                    <li>
                                        <label className="container-label" for="menos_94_cm">
                                        <input
                                    type="radio"
                                    id="menos_94_cm"
                                    name="perimetro_cintura"
                                    value="menos_94_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "menos_94_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Menos de 94 cm
                             </label>
                                    </li>
                                    <li>
                                    <label className="container-label" for="entre_94_102_cm">
                                        <input
                                    type="radio"
                                    id="entre_94_102_cm"
                                    name="perimetro_cintura"
                                    value="entre_94_102_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "entre_94_102_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Entre 94-102 cm
                             </label>
                                    </li>
                                    <li>
                                    <label className="container-label" for="mas_102_cm">
                                        <input
                                    type="radio"
                                    id="mas_102_cm"
                                    name="perimetro_cintura"
                                    value="mas_102_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "mas_102_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Más de 102 cm
                             </label>
                                    </li>

                                </div>
                                <div className="col-md-6 mujeres">
                                    <p className="subtitle">Mujeres</p>
                                    <li>
                                    <label className="container-label" for="menos_80_cm">
                                        <input
                                    type="radio"
                                    id="menos_80_cm"
                                    name="perimetro_cintura"
                                    value="menos_80_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "menos_80_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Menos de 80 cm
                             </label>
                                    </li>
                                    <li>
                                    <label className="container-label" for="entre_80_88_cm">
                                        <input
                                    type="radio"
                                    id="entre_80_88_cm"
                                    name="perimetro_cintura"
                                    value="entre_80_88_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "entre_80_88_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Entre 80-88 cm
                             </label>
                                    </li>
                                    <li>
                                    <label className="container-label" for="mas_88_cm">
                                        <input
                                    type="radio"
                                    id="mas_88_cm"
                                    name="perimetro_cintura"
                                    value="mas_88_cm"
                                    checked={respuestasValues['perimetro_cintura'] === "mas_88_cm"}
                                    onChange={handleInputValuesChange}
                                    />
                                            <span className="checkmark"></span>
                                 Más de 88 cm
                             </label>
                                    </li>
                                </div>
                            </div>
                        </div>
                        <p className={respuestasClases['perimetro_cintura']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(2)
                                    setCompleted(10)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 3 de 8</p>
                            <button onClick={() => {
                                nextQuestion('perimetro_cintura', 40, 4);
                            }} >SIGUIENTE</button>
                        </div>

                    </div>
                )}

                {questionNumber == 4 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaCuatro} alt="" />
                            <p className="title" >¿Realiza habitualmente al menos 30 minutos de actividad
fisica, en el trabajo y/o en el tiempo libre?</p>
                        </div>
                        <div className="inputs">
                            <li>
                            <label className="container-label" for="actividadFisicaSi">
                                        <input
                                    type="radio"
                                    id="actividadFisicaSi"
                                    name="actividad_fisica"
                                    value="si"
                                    checked={respuestasValues['actividad_fisica'] === "si"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                               Si
                            </label>
                            </li>
                            <li>
                            <label className="container-label" for="actividadFisicaNo">
                                        <input
                                    type="radio"
                                    id="actividadFisicaNo"
                                    name="actividad_fisica"
                                    value="no"
                                    checked={respuestasValues['actividad_fisica'] === "no"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                No
                            </label>
                            </li>


                        </div>
                        <p className={respuestasClases['actividad_fisica']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(3)
                                    setCompleted(20)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 4 de 8</p>
                            <button onClick={() => {
                                 nextQuestion('actividad_fisica', 50, 5);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}

                {questionNumber == 5 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaCinco} alt="" />
                            <p className="title" >¿Con qué frecuencia come verduras o fruta?</p>
                        </div>
                        <div className="inputs">
                            <li>
                            <label className="container-label" for="verdurasSi">
                                        <input
                                    type="radio"
                                    id="verdurasSi"
                                    name="verduras"
                                    value="si"
                                    checked={respuestasValues['verduras'] === "si"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                               Todos los días
                             </label>
                            </li>
                            <li>
                            <label className="container-label" for="verdurasNo">
                                        <input
                                    type="radio"
                                    id="verdurasNo"
                                    name="verduras"
                                    value="no"
                                    checked={respuestasValues['verduras'] === "no"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                 No todos los días
                             </label>
                            </li>
                        </div>
                        <p className={respuestasClases['verduras']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(4)
                                    setCompleted(40)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 5 de 8</p>
                            <button onClick={() => {
                                 nextQuestion('verduras', 65, 6);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}

                {questionNumber == 6 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaSeis} alt="" />
                            <p className="title" >¿Toma medicación para la presión regularmente?</p>
                        </div>
                        <div className="inputs">
                            <li>
                            <label className="container-label" for="medicacionNo">
                                        <input
                                    type="radio"
                                    id="medicacionNo"
                                    name="medicacion"
                                    value="no"
                                    checked={respuestasValues['medicacion'] === "no"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                              No
                            </label>
                            </li>
                            <li>
                            <label className="container-label" for="medicacionSi">
                                        <input
                                    type="radio"
                                    id="medicacionSi"
                                    name="medicacion"
                                    value="si"
                                    checked={respuestasValues['medicacion'] === "si"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                Si
                            </label>
                            </li>
                        </div>
                        <p className={respuestasClases['medicacion']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(5)
                                    setCompleted(65)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 6

                                 de 8</p>
                            <button onClick={() => {
                                 nextQuestion('medicacion', 80, 7);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}

                {questionNumber == 7 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaSiete} alt="" />
                            <div>
                                <p className="title" >¿Le han encontrado alguna vez valores de glucosa (azúcar) altos?
 </p>
                                <p className="text-yellow" >(p.e. en un control médico, durante una enfermedad, durante el embarazo)?</p>
                            </div>
                        </div>
                        <div className="inputs">
                            <li>
                            <label className="container-label" for="glucosaNo">
                                        <input
                                    type="radio"
                                    id="glucosaNo"
                                    name="glucosa"
                                    value="no"
                                    checked={respuestasValues['glucosa'] === "no"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                               No
                             </label>
                            </li>
                            <li>
                            <label className="container-label" for="glucosaSi">
                                        <input
                                    type="radio"
                                    id="glucosaSi"
                                    name="glucosa"
                                    value="si"
                                    checked={respuestasValues['glucosa'] === "si"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                 Si
                             </label>
                            </li>
                        </div>
                        <p className={respuestasClases['glucosa']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(6)
                                    setCompleted(80)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 7 de 8</p>
                            <button onClick={() => {
                                 nextQuestion('glucosa', 92, 8);
                            }} >SIGUIENTE</button>
                        </div>
                    </div>
                )}

                {questionNumber == 8 && (
                    <div className="container-inputs">
                        <div className="modal-test">
                            <img src={PreguntaOcho} alt="" />
                            <div>
                                <p className="title" >¿Alguno de sus familiares allegados u otros parientes ha sido diagnosticado con diabetes?

</p>
                                <p className="text-yellow" >(tipo 1 o tipo 2)</p>
                            </div>
                        </div>
                        <div className="inputs">
                            <li>
                            <label className="container-label" for="familiares_diabetes_no">
                                        <input
                                    type="radio"
                                    id="familiares_diabetes_no"
                                    name="familiares_diabetes"
                                    value="no"
                                    checked={respuestasValues['familiares_diabetes'] === "no"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                              No
                            </label>
                            </li>
                            <li>
                            <label className="container-label" for="abuelos_tios_primos">
                                        <input
                                    type="radio"
                                    id="abuelos_tios_primos"
                                    name="familiares_diabetes"
                                    value="abuelos_tios_primos"
                                    checked={respuestasValues['familiares_diabetes'] === "abuelos_tios_primos"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                Si: abuelos, tía, tío, primo hermano
                            </label>
                            </li>
                            <li>
                            <label className="container-label" for="padres_hermanos_hijos">
                                        <input
                                    type="radio"
                                    id="padres_hermanos_hijos"
                                    name="familiares_diabetes"
                                    value="padres_hermanos_hijos"
                                    checked={respuestasValues['familiares_diabetes'] === "padres_hermanos_hijos"}
                                    onChange={handleInputValuesChange}
                                    />
                                    <span className="checkmark"></span>
                                Si: padres, hermanos o hijos
                            </label>
                            </li>
                        </div>
                        <p className={respuestasClases['familiares_diabetes']} >Seleccione una opción</p>
                        <div className="container-buttons">
                            <button
                                onClick={() => {
                                    setQuestionNumber(7)
                                    setCompleted(92)
                                }}
                            >ANTERIOR</button>
                            <p>pregunta 8 de 8</p>
                            <button onClick={() => {
                                finishTest()
                                // setQuestionNumber(8)
                                // setCompleted(100)
                                // redirectToResult()

                            }} >TERMINAR</button>
                        </div>
                    </div>
                )}
                <div className="marca">
                    {/*<span>AR-3565 08/21</span>
                    <img src={marca} alt="International Diabetes Federation"/>*/}
                </div>
            </div>
        </div>
    )
}