
import React from 'react'
import ReactHtmlParser from 'react-html-parser';


export default function DesMerCol(props) {

    return (
        <div class="dietas">
            <h3>{props.title}</h3>
            <div class=" swiper-container sw-desayuno">

                <div class="swiper-wrapper grid-mobile">
                    <div class="swiper-slide">
                        <div className="image">
                            <img src={`${props.api}/diets/media/${props['foot_file']}`} />
                        </div>
                        <div className="content">
                            <h4>{props.foot}</h4>
                            {ReactHtmlParser(props.foot_description)}
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div className="image">
                            <img src={`${props.api}/diets/media/${props['foot_file2']}`} />
                        </div>
                        <div className="content">
                            <h4>{props.foot2}</h4>
                            {ReactHtmlParser(props.foot_description2)}
                        </div>
                    </div>
                </div>


            </div>
        </div>


    )
}
