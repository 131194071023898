import React, { useState } from 'react'

import Slider from "react-slick";

//IMG
/*import IconoDedo from "../img/icono-dedo.png"*/
import IconoDedo from "../img/mano-test.png"
import IconoRenal from "../img/icono-riñones.png"
import IconoCorazon from "../img/icono-corazon.png"
import PersonasOrganos from "../img/icono-grupo.png"


export default function NumerosSlider() {

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div>
                {dots}
            </div>
        )
    };

    const [sliderID, setSliderID] = useState('numeros-slider')

    const handleFuentes = () => {
        setSliderID('numeros-slider-fuentes')
    }

    const handleSliders = () => {
        setSliderID('numeros-slider')
    }

    return (
        <div id={sliderID} className="numeros-slider-container" >
            {sliderID === 'numeros-slider' ? (
                <div>
                    <div className="titles">
                        <h5>CAMPAÑA / NÚMEROS EN EL MUNDO</h5>
                        <h2 className="font-weight-bold text-dark" >Enfermedades</h2>
                        <h3>Renales, Cardiovasculares y Diabetes</h3>
                    </div>
                    <Slider {...settings} >
                        <div className="each-fade">
                            <div className="d-flex justify-content-center">
                                <div className="icono mr-5">
                                    <img src={IconoDedo} alt="" />
                                </div>
                                <div className="text">
                                    <p className="line-one" >463</p>
                                    <p className="line-two" > millones de personas</p>
                                    <p className="line-three" >viven con DIABETES en el mundo</p>
                                </div>
                            </div>
                        </div>
                        <div className="each-fade">
                            <div className="d-flex justify-content-center">
                                <div className="icono mr-5">
                                    <img src={IconoRenal} alt="" />
                                </div>
                                <div className="text">
                                    <p className="line-one" >200</p>
                                    <p className="line-two" > millones de personas</p>
                                    <p className="line-three" >con enfermedades RENALES CRÓNICAS en el mundo</p>
                                </div>
                            </div>
                        </div>
                        <div className="each-fade">
                            <div className="d-flex justify-content-center">
                                <div className="icono mr-5">
                                    <img src={IconoCorazon} alt="" />
                                </div>
                                <div className="text">
                                    <p className="line-one" >17.7</p>
                                    <p className="line-two" > millones de muertes</p>
                                    <p className="line-three" >con enfermedades CARDIOVASCULARES cada año</p>
                                </div>
                            </div>
                        </div>
                        <div className="each-fade">
                            <div className="d-flex justify-content-center">
                                <div className="icono">
                                    <img src={PersonasOrganos} alt="" />
                                </div>
                                <div className="text ml-5">
                                    <p className="line-one" >40%</p>
                                    <p className="line-two" > de pacientes</p>
                                    <p className="line-three" >con FALLAS CARDÍACAS tienen ENFERMEDADES RENALES</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="fuentes-button" >
                        <button onClick={handleFuentes} href=''>Fuente...</button>
                    </div>
                </div>
            ) : (
                    <div className="fuente-container" >
                        <button onClick={handleSliders} >Cerrar Fuentes</button>
                        <br />
                        <br />
                        <p>
                            <a href="https://www.idf.org/aboutdiabetes/what-is-diabetes/facts-figures.html">Revisión 28 de Mayo de 2020</a> World Health Organization. WHO World Heart Day 2017: Scale up prevention of heart attack and stroke: <a href="http://www.who.int/cardiovascular_diseases/world-heart-day-2017/en/">World Health Organization</a>: 2017 [cited 2018 Jul 25]. Ojo A. Addressing the global burden of chronic kidney disase through clinical and translational research. Trans Am Clin Climatol Assoc 2014: 125:229-43: discussion 243-6. <a href="https://www.idf.org/e-library/epidemiology-research/diabetes-atlas.html">International Diabetes Foundation</a>. Diabetes Atlas Eighth Edition 2017 [p43]
                        </p>

                    </div>
                )}
        </div>
    )
}