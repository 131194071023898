import React from 'react'
import Play from '../img/play-button.png'


export default function Video() {

    const sendView = async () => {
        console.log("VIDEO VIEW")
        const myBody = {
            formName: "view"
        }
        const rawResponse = await fetch('https://la55gl35y8.execute-api.us-east-1.amazonaws.com/default/mqg-senddb-prod-sendDB', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(myBody)
        });
        const content = await rawResponse.text()

        console.log(content)
    }


    return (
        <div id="container-video"  >
            <section id="video" data-toggle="modal" data-target="#modalVideo">
                <h4>CONOCE LOS RIESGOS <br />
                    ANTES DE QUE SEA TARDE
                </h4>
                <img onClick={sendView} src={Play} />
            </section>

        </div>
    )
}
