import React, { useRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { API_MATERIAL } from '../store/constants';
import Slider from "react-slick";
import Foto from '../img/controlPeso.png'
import LAPDI from '../img/logo2.png'
import { Link } from 'react-router-dom'


export default function ActividadesSlider() {
    const settingsMobile = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "30px",

        arrows: false,
        slidesToShow: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    };
    const ref = useRef(null);
    const settings = {


        swipeToSlide: true,
        dots: false,
        pagination: false,
        infinite: true,
        speed: 5004444444444444444444444444444444444,
        autoplay: false,
        autoplaySpeed: 2444444444444444444444444444444444444444444000,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref,
    };

    const events = useSelector((state) => state.eventsReducer.events, shallowEqual);

    console.log(events[0])

    const goNext = () => {
        if (ref.current !== null) {
            ref.current.slickNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null) {
            ref.current.slickPrev();
        }
    };
    return (
        <div id="actividades-slider " className="" >

            <div className="container  " style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                <Slider className="actividades-mobile" {...settings} >

                    {events.map(event => (

                        <div className="d-flex ">

                            <div className="actividad-slider" style={{ backgroundColor: "#F4F4F4" }}>
                                <div className="image-container" >
                                    <img className="foto-principal" src={`${API_MATERIAL}/events/media/${event['photo']}`} />
                                </div>
                                <p style={{ color: "#0085c9" }}>ORGANIZA :  {event['organizer_name']}</p>
                                <h4>{event['title']}</h4>
                                <h5 style={{ color: "#0085c9" }}>{event['subtitle']}</h5>
                                <div className="logo-organizador" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                    <Link className="d-inline" to={`/calendario-de-actividades/${event['id_event']}`} >
                                        <div className="d-flex justify-content-start saber-mas">
                                            <h5 className="d-inline" style={{ letterSpacing: "0px", color: "#05A9FD", fontWeight: "700" }}>

                                                SABER
                                            </h5>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.411" height="22.411" viewBox="0 0 22.411 22.411">
                                                    <g id="Group_2848" data-name="Group 2848" transform="translate(1.5 1.5)">
                                                        <path id="Path_2700" data-name="Path 2700" d="M6.657,4.5h15.1a2.157,2.157,0,0,1,2.157,2.157v15.1a2.157,2.157,0,0,1-2.157,2.157H6.657A2.157,2.157,0,0,1,4.5,21.754V6.657A2.157,2.157,0,0,1,6.657,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                        <path id="Path_2701" data-name="Path 2701" d="M18,12v8.627" transform="translate(-8.295 -6.608)" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                        <path id="Path_2702" data-name="Path 2702" d="M12,18h8.627" transform="translate(-6.608 -8.295)" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                    </g>
                                                </svg>
                                            </span>

                                        </div>
                                    </Link>
                                    <img src={`${API_MATERIAL}/events/media/${event['organizer_logo']}`} />
                                </div>

                            </div>

                        </div>

                    ))}
                </Slider >



                <Slider className="logos-desktop banner-actividades-desktop" {...settings}>

                    {events.map(event => (
                        <div className="d-flex col-sm-12 ">
                            <div className="actividad-slider" style={{ backgroundColor: "#F4F4F4" }}>

                                <div className="actividad-header">
                                    <p >ORGANIZA : {event['organizer_name']}</p>
                                    <h4>{event['title']}</h4>
                                    <h5 className="subtitle" >{event['subtitle']}</h5>
                                </div>
                                <div className="logo-organizador">
                                    <div className="saber-mas d-flex mt-0">
                                        <Link className="d-inline" to={`/calendario-de-actividades/${event['id_event']}`} >
                                            <h5

                                                style={{ letterSpacing: "0px", color: "#05A9FD", marginRight: "1em", fontWeight: "700" }}>
                                                SABER
  </h5>
                                        </Link>

                                        <Link to={`/calendario-de-actividades/${event['id_event']}`} >
                                            <svg style={{ marginTop: "1em" }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Icon_feather-plus-square" data-name="Icon feather-plus-square" transform="translate(-3 -3)">
                                                    <path id="Path_2700" data-name="Path 2700" d="M7.5,4.5h21a3,3,0,0,1,3,3v21a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                    <path id="Path_2701" data-name="Path 2701" d="M18,12V24" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                    <path id="Path_2702" data-name="Path 2702" d="M12,18H24" fill="none" stroke="#05a9fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                                </g>
                                            </svg>
                                        </Link>

                                    </div>
                                    <img src={`${API_MATERIAL}/events/media/${event['organizer_logo']}`} style={{width:'50%'}} />
                                </div>


                            </div>
                            <div className="image-container" >
                                <img src={`${API_MATERIAL}/events/media/${event['photo']}`} />
                            </div>
                        </div>
                    ))}



                </Slider>
            </div>

            <a class="carousel-control-prev" onClick={goPrev} role="button" data-slide="prev"> <span class="carousel-control-prev-icon-blue" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a>
            <a class="carousel-control-next" onClick={goNext} role="button" data-slide="next"> <span class="carousel-control-next-icon-blue" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
        </div>
    )
}