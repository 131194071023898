import React from 'react'
import PersonasDiabetes from '../img/personas-diabetes.png'
import Renal from '../img/icono-renal.png'
import Cardio from '../img/icono-cardiovascular.png'
import PersonasFallas from'../img/personas-fallas.png'


import WOW from 'wowjs';
import { Component } from 'react'

export default class Numeros extends Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }


    render() {
        return (
            <div>
                   <section id="numeros">
        <div className="container">
            <h6 className="text-celeste mb-3">NÚMEROS EN EL MUNDO</h6>
            <h3 className="m-0">Enfermedades</h3>
            <h3 className="mb-5" style={{fontWeight: 300}}>Renales, Cardiovasculares y Diabetes </h3>
            <div className="numeros">
                <div className="row numero-dato dato-1">
                    <div className="col-md-6 text-center wow slideInLeft">
                        <p className="cifra">463 millones de personas</p>
                        <p className="info">viven con <span className="highlight">DIABETES</span> en el mundo</p>
                    </div>
                    <div className="col-md-6  wow slideInRight"> <img src={PersonasDiabetes} alt="Personas con diabetes"/> </div>
                </div>
                <div className="row numero-dato dato-2 reverse-mobile">
                    <div className="col-md-6 ico  wow slideInLeft"> <img src={Renal} alt="Enfermedades renales"/> </div>
                    <div className="col-md-6  wow slideInRight">
                        <p className="cifra">200 millones de personas</p>
                        <p className="info">con enfermedades <span className="highlight">RENALES CRÓNICAS</span> en el mundo</p>
                    </div>
                </div>
                <div className="row numero-dato dato-3">
                    <div className="col-md-6 text  wow slideInLeft">
                        <p className="cifra">17.7 millones de muertes</p>
                        <p className="info">por enfermedades <span className="highlight">CARDIOVASCULARES</span> cada año</p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center  wow slideInRight"> <img src={Cardio} accesskey="Enfermedades Cardiovasculares"/> </div>
                </div>
                <div className="row numero-dato dato-4  wow fadeInUp">
                    <div className="col-12 text-center">
                        <p className="cifra">40% de pacientes</p>
                        <p className="info">con <span className="highlight">FALLAS CARDÍACAS</span> tienen <span className="highlight">ENFERMEDADES RENALES</span></p>
                    </div>
                    <div className="col-12 text-center"> <img src={PersonasFallas} className="m-auto" alt="Fallas Cardiacas y Renales"/> </div>
                </div>
            </div>
            <ul className="fuentes"> Fuentes
                <li> https://www.idf.org/aboutdiabetes/what-is-diabetes/facts-figures.html. Revisión 28 de Mayo 2020
                    <br/> World Health Organization. WHO World Heart Day 2017: Scale up prevention of heart attack and stroke: World Health Organization; 2017 [cited 2018 Jul 25]. Disponible en: http://www.who.int/cardiovascular_diseases/world-heart-day-2017/en/.</li>
                <li>Ojo A. Addressing the global burden of chronic kidney disease through clinical and translational research. Trans Am Clin Climatol Assoc 2014; 125:229-43; discussion 243-6.</li>
                <li>International Diabetes Foundation. Diabetes Atlas Eighth Edition 2017 (p43). Available at https://www.idf.org/e-library/epidemiology-research/diabetes-atlas.html</li>
            </ul>
        </div>
    </section>
            </div>
        )
    }
}
