import {
    FETCH_DOCTORS
  } from "../constants";
  
  const initialState = {
    doctors: [],
  };





export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOCTORS:
            return Object.assign({}, state, {
            doctors: action.doctors
            });
        default:
            return state;
    }
}