import React, { useState } from "react";
import { Link } from "react-router-dom";

import IconLuna from '../img/icono-luna.JPG'

import Modal from 'react-bootstrap/Modal';


export default function IconosHome() {

    const [showVivo, setShowVivo] = useState(false);

    const handleCloseVivo = () => setShowVivo(false);
    const handleShowVivo = () => setShowVivo(true);

    return (
        <section id="home-selector" className="section--bg">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div id="icono-diabetes" className="iconos col-md-3">
                        <Link to="/espacio-diabetes" className="selector">
                            {/* <img src={AprendeDiabetes} alt="" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="170" height="126" viewBox="0 0 170 106">
                                <g transform="translate(-215 -626)">
                                    <g transform="translate(29)">
                                        {/* <text transform="translate(271 748)" fill="#0085c9" font-size="15" font-family="SegoeUI, Segoe UI">
                                            <tspan x="-84.642" y="0">TU ESPACIO DE DIABETES</tspan>
                                        </text> */}
                                        <g transform="translate(224 626)" fill="#fff">
                                            <path d="M 46.5 89 C 40.76078414916992 89 35.19535446166992 87.87686157226563 29.95830726623535 85.66178131103516 C 24.89792251586914 83.52141571044922 20.35238456726074 80.45644378662109 16.44796943664551 76.55203247070313 C 12.54355430603027 72.64761352539063 9.478584289550781 68.10207366943359 7.338215351104736 63.04169082641602 C 5.123138427734375 57.80464553833008 4 52.23921585083008 4 46.5 C 4 40.76078414916992 5.123138427734375 35.19535446166992 7.338215351104736 29.95830726623535 C 9.478584289550781 24.89792251586914 12.54355430603027 20.35238456726074 16.44796943664551 16.44796943664551 C 20.35238456726074 12.54355430603027 24.89792251586914 9.478584289550781 29.95830726623535 7.338215351104736 C 35.19535446166992 5.123138427734375 40.76078414916992 4 46.5 4 C 52.23921585083008 4 57.80464553833008 5.123138427734375 63.04169082641602 7.338215351104736 C 68.10207366943359 9.478584289550781 72.64761352539063 12.54355430603027 76.55203247070313 16.44796943664551 C 80.45644378662109 20.35238456726074 83.52141571044922 24.89792251586914 85.66178131103516 29.95830726623535 C 87.87686157226563 35.19535446166992 89 40.76078414916992 89 46.5 C 89 59.70161437988281 82.93283081054688 71.99739837646484 72.35417175292969 80.23455047607422 C 68.81226348876953 82.99343109130859 64.82843017578125 85.15153503417969 60.51279830932617 86.64933776855469 C 56.01861572265625 88.20912170410156 51.30403137207031 89 46.5 89 Z" stroke="none" />
                                            <path d="M 46.5 8 C 25.27101898193359 8 8 25.27101898193359 8 46.5 C 8 67.72898101806641 25.27101898193359 85 46.5 85 C 55.02946090698242 85 63.33819961547852 82.18704223632813 69.89668273925781 77.07847595214844 C 79.49505615234375 69.60463714599609 85 58.45922088623047 85 46.5 C 85 25.27101898193359 67.72898101806641 8 46.5 8 M 46.5 0 C 72.18124389648438 0 93 20.81876373291016 93 46.5 C 93 61.42079925537109 85.97239685058594 74.70021820068359 74.81166076660156 83.39060211181641 C 67.16722106933594 89.34503936767578 57.26044082641602 93 46.5 93 C 20.81876373291016 93 0 72.18124389648438 0 46.5 C 0 20.81876373291016 20.81876373291016 0 46.5 0 Z" stroke="none" fill="#6bb5db" />
                                        </g>
                                        <g className="tag-g" transform="translate(185 653)">
                                            <path d="M98.125,0H76.9A4.9,4.9,0,0,0,72,4.9V32.656A5.35,5.35,0,0,0,77.344,38H98.125a1.188,1.188,0,0,0,0-2.375H77.344a2.969,2.969,0,0,1,0-5.937H98.125A1.188,1.188,0,0,0,99.313,28.5V1.188A1.188,1.188,0,0,0,98.125,0ZM96.938,27.313H79.719V2.375H96.938Zm-22.562.9V4.9A2.526,2.526,0,0,1,76.9,2.375h.445V27.313A5.313,5.313,0,0,0,74.375,28.216Z" />
                                            <path d="M149.969,424H129.188a1.188,1.188,0,0,0,0,2.375h20.781a1.188,1.188,0,0,0,0-2.375Z" transform="translate(-51.844 -392.531)" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p>EDUCACIÓN</p>
                        </Link>
                    </div>
                    <div id="icono-normal" className="iconos col-md-3">
                        <a
                            href="#consejos"
                            className="selector"
                            aria-current="page"
                        >
                            {/* <img src={ConsejosTips} alt="" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="122" height="126" viewBox="0 0 122 106"><g transform="translate(-499 -626)"><g transform="translate(7)">
                                {/* <text transform="translate(553 748)" fill="#0085c9" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="-60.022" y="0">CONSEJOS &amp; TIPS</tspan>
                                </text> */}
                                <g transform="translate(507 626)" fill="#fff" stroke="#6bb5db" stroke-width="8"><circle cx="46.5" cy="46.5" r="46.5" stroke="none" /><circle cx="46.5" cy="46.5" r="42.5" fill="none" /></g><path d="M81.859,0a13.609,13.609,0,0,0-7.617,24.89V37.115H89.476V24.89A13.611,13.611,0,0,0,81.859,0ZM76.417,28.409H87.3v2.178H76.417ZM77.506,14.7A1.089,1.089,0,1,1,78.6,13.61,1.09,1.09,0,0,1,77.506,14.7Zm5.442,2.175v9.361H80.77V16.874Zm2.175-3.264A1.089,1.089,0,1,1,86.212,14.7,1.09,1.09,0,0,1,85.123,13.61ZM76.417,34.94V32.762H87.3V34.94ZM91.789,19.284a11.516,11.516,0,0,1-3.969,4.086l-.52.318v2.546H85.123V16.874h1.089A3.265,3.265,0,1,0,83.136,14.7H80.582a3.263,3.263,0,1,0-3.076,2.175H78.6v9.361H76.417V23.688l-.52-.318a11.434,11.434,0,1,1,15.892-4.086Z" transform="translate(471.751 655)" fill="#3b3b3b" /></g></g></svg>
                            <p>CONSEJOS & TIPS</p>

                        </a>
                    </div>
                    <div id="icono-normal" className="iconos col-md-3">
                        <Link to="/test-de-riesgo" className="selector">
                            {/* <img src={TestRiesgo} alt="" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="108" height="126" viewBox="0 0 108 106">
                                <g transform="translate(-767 -626)">
                                    {/* <text transform="translate(821 748)" fill="#0085c9" font-size="15" font-family="SegoeUI, Segoe UI">
                                        <tspan x="-53.859" y="0">TEST DE RIESGO</tspan>
                                    </text> */}
                                    <g transform="translate(774 626)" fill="#fff" stroke="#6bb5db" stroke-width="8">
                                        <circle cx="46.5" cy="46.5" r="46.5" stroke="none" />
                                        <circle cx="46.5" cy="46.5" r="42.5" fill="none" />
                                    </g>
                                    <g transform="translate(797.625 650.693)">
                                        <path d="M30.481,14.012l-1.647-1.694a.354.354,0,0,0-.262-.112h0a.34.34,0,0,0-.262.112L16.892,23.82l-4.155-4.155a.362.362,0,0,0-.524,0l-1.666,1.666a.373.373,0,0,0,0,.533l5.241,5.241a1.657,1.657,0,0,0,1.095.533,1.737,1.737,0,0,0,1.086-.515h.009L30.49,14.546A.4.4,0,0,0,30.481,14.012Z" transform="translate(2.337 2.923)" fill="#3b3b3b" />
                                        <path d="M22.841,6a16.839,16.839,0,1,1-11.914,4.932A16.734,16.734,0,0,1,22.841,6m0-2.62A19.466,19.466,0,1,0,42.307,22.841,19.463,19.463,0,0,0,22.841,3.375Z" transform="translate(0 0)" fill="#3b3b3b" />
                                    </g>
                                </g>
                            </svg>
                            <p>TEST DE RIESGO</p>

                        </Link>
                    </div>
                    {/*<div id="icono-normal" className="iconos col-6 col-md-3">
                        <Link onClick={handleShowVivo} to="/calendario-de-actividades" className="selector">
                             <img src={Actividades} alt="" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="93" height="126" viewBox="0 0 93 106"><g transform="translate(-1017 -626)"><g transform="translate(-32)">
                                 <text transform="translate(1097 748)" fill="#0085c9" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="-44.865" y="0">ACTIVIDADES</tspan>
                                </text>
                                <g transform="translate(1049 626)" fill="#fff" stroke="#6bb5db" stroke-width="8"><circle cx="46.5" cy="46.5" r="46.5" stroke="none" /><circle cx="46.5" cy="46.5" r="42.5" fill="none" /></g><g transform="translate(1080 655)"><path d="M32.611,16.751V3.314a1.055,1.055,0,0,0-1.055-1.055H26.4v-1.2a1.055,1.055,0,1,0-2.109,0v1.2H17.36v-1.2a1.055,1.055,0,0,0-2.109,0v1.2H8.323v-1.2a1.055,1.055,0,0,0-2.109,0v1.2H1.055A1.055,1.055,0,0,0,0,3.314V31.556a1.055,1.055,0,0,0,1.055,1.055h15.7a11.216,11.216,0,1,0,15.86-15.86ZM2.109,4.369H30.5V9.038H2.109Zm0,26.133V11.147H30.5v3.982A11.217,11.217,0,0,0,15.129,30.5Zm22.669,3.389a9.113,9.113,0,1,1,9.113-9.113A9.123,9.123,0,0,1,24.778,33.891Z" /><g transform="translate(23.353 20.017)"><path d="M342.228,292.882h-2.536v-2.536a1.146,1.146,0,0,0-2.292,0v3.682a1.146,1.146,0,0,0,1.146,1.146h3.682a1.146,1.146,0,1,0,0-2.292Z" transform="translate(-337.4 -289.2)" /></g></g></g></g></svg>
                            <p>CALENDARIO DE ACTIVIDADES</p>

                        </Link>
                    </div>*/}
                </div>
            </div>
            <Modal
                keyboard
                enforceFocus
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showVivo}
                onHide={handleCloseVivo}
                className="modal-vivo">
                <Modal.Body className="proximamente-modal">

                    <img src={IconLuna} alt="" />
                    <div className="container">
                        <p className="">#MásQueGlucosa</p>
                        <h2 className="text-dark" >PRÓXIMAMENTE</h2>
                        <p className="text-dark" >Este contenido se encuentra en desarrollo</p>
                    </div>
                </Modal.Body>
            </Modal>

        </section >
    );
}
