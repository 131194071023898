import React from 'react';

import hr_blanco from '../img/hr-100-blanco.png'

import { API_MATERIAL } from '../store/constants';
import ReactHtmlParser from 'react-html-parser'


export default function ConsejosHeader(props) {

    return (
        <section id="consejos-header" className="consejos-header" >
            <div className="image">
                <img src={`${API_MATERIAL}/diets/media/${props.consejo['file']}`} alt="" />
            </div>
            <div className="content fondo-gradient-blue">
                <div>
                    <h2>{props.consejo['title']}</h2>
                    <p>{ReactHtmlParser(props.consejo['description'])}</p>
                    <img src={hr_blanco} alt="" />
                </div>
            </div>
        </section>
    )
}